<template>
  <div id="writeArticles" class="vx-row" style="padding-left: 20px">
    <div class="vx-col w-full md:w-2/3" style="min-width: 630px;">
      <div class="flex flex-wrap items-center flex-grow">
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <ul class="flex flex-wrap items-center">
          <li>
            <vs-radio @change="photoCheck('off')" color="success" v-model="sendData.type" vs-value="1">일반기사</vs-radio>
          </li>
          <li>
            <vs-radio @change="photoCheck('on')" color="danger" v-model="sendData.type" vs-value="9">포토기사</vs-radio>
          </li>
        </ul>
      </div>

      <div id="toolbar4" slot="toolbar">
        <span style="float: left; font-size: 19px;"><b>포털 제목 |  </b></span>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button id="custom-button-4-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_p_sbj.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>
          
          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
          <option value="“"/>
          <option value="”"/>
          <option value="‘"/>
          <option value="’"/>
        </select>
      </div>
      <local-quill-editor v-model="sendData.portalTitle"
                    ref="quillEditor_p_sbj"
                    :options="editorOption4"
                    @change="onEditorChange($event)">>
      </local-quill-editor>
      <div style="padding: 0px; margin-top: 7px; font-size: 20px; font-weight: bold; line-height: 1.5;">
        <p>네이버 제목 동의어 키워드 (최대 3개)</p>
      </div>
      <div style="padding: 0px">
        <v-select ref="titleTagSelect" class="vs-con-input-label titleTags" style="width: 100%;" @option:created="tagFocusFlg = true;" @search:blur="setTitleTag" no-drop taggable multiple autofocus v-model="sendData.titleTags"/>
      </div>
      <div id="toolbar2" slot="toolbar">
        <span style="float: left; font-size: 19px;"><b>홈페이지 제목 |  </b></span>
        <button class="ql-strike" style="width: 55px;">원글자</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button id="custom-button-2-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_b_sbj.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>
          
          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
          <option value="“"/>
          <option value="”"/>
          <option value="‘"/>
          <option value="’"/>
        </select>
      </div>
      <local-quill-editor v-model="sendData.mainTitle"
                    ref="quillEditor_b_sbj"
                    :options="editorOption2"
                    @change="onEditorChange($event)">
      </local-quill-editor>
      
      <div id="toolbar3" slot="toolbar" v-if="sendData.type != '9'">
        <span style="float: left; font-size: 19px;"><b>부 제 |  </b></span>
        <button class="ql-strike" style="width: 55px;">원글자</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button id="custom-button-3-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_s_sbj.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>
          
          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
          <option value="“"/>
          <option value="”"/>
          <option value="‘"/>
          <option value="’"/>
        </select>
      </div>
      <local-quill-editor v-model="sendData.subTitle" v-if="sendData.type != '9'"
                    ref="quillEditor_s_sbj"
                    :options="editorOption3"
                    @change="onEditorChange($event)">
      </local-quill-editor>
      <h4 id='textcount'> 글자수 {{ textcount }} / 원고지 {{ papers }}매</h4>      
      <div id="toolbar" slot="toolbar">
        <button class="ql-blockquote" style="width: 80px; border-left: 3px solid;">내부부제</button>
        <button class="ql-code-block" style="width: 80px; border-left: 2px solid;">내부단락</button>
        <button class="ql-header" value="1" style="width: 55px; border-left: 1px solid #A6A6A6;">박스1</button>
        <button class="ql-header" value="2" style="width: 55px; border-left: 2px solid #A6A6A6;">박스2</button>
        <button class="ql-header" value="3" style="width: 65px; border-left: 3px solid #D6D6D6;">말풍선</button>
        <button class="ql-strike" style="width: 55px;">원글자</button>
        <span class="ql-align">
          <select class="ql-align" title="Align"></select>
        </span>
        <button class="ql-bold">Bold</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-underline">Underline</button>
        <button class="ql-link">Link</button>
        <button class="ql-list" value="ordered">List</button>
        <button class="ql-list" value="bullet">List</button>
        <span class="ql-color">
          <select class="ql-color" title="Colour"></select>
        </span>
        <button class="ql-image">Image</button>
        <button class="ql-video">Video</button>
        <button id="custom-button-1-4" style="width: 50px" @click="customButtonClick($refs.quillEditor_descr.quill, sendData.byline)">매크로</button>
        <select class="ql-customSpecial" style="line-height: 1.8em;">
          <option value="※"/>
          <option value="▲"/>
          <option value="△"/>
          <option value="ⓒ"/>
          <option value="→"/>
          <option value="…"/>
          <option value="·"/>
          <option value="㈜"/>

          <option value="￠"/>
          <option value="￡"/>
          <option value="￥"/>
          <option value="￦"/>
          <option value="₠"/>
          <option value="₡"/>
          <option value="₢"/>
          <option value="₣"/>

          <option value="☚"/>
          <option value="☛"/>
          <option value="☜"/>
          <option value="☝"/>
          <option value="☞"/>
          <option value="☟"/>
          <option value="☢"/>
          <option value="♺"/>

          <option value="♩"/>
          <option value="♪"/>
          <option value="♫"/>
          <option value="♬"/>
          <option value="♭"/>
          <option value="♮"/>
          <option value="♯"/>
          <option value="♰"/>

          <option value="☼"/>
          <option value="☀"/>
          <option value="☽"/>
          <option value="☾"/>
          <option value="☁"/>
          <option value="☂"/>
          <option value="☃"/>
          <option value="☄"/>

          <option value="↑"/>
          <option value="↓"/>
          <option value="“"/>
          <option value="”"/>
          <option value="‘"/>
          <option value="’"/>
        </select>
      </div>
      <local-quill-editor
                    v-model="content"
                    class="quillEditor_descr"
                    ref="quillEditor_descr"
                    :options="editorOption"
                    @change="onEditorChange($event)"
                    style="height: calc(100vh - 310px)"
                    >
      </local-quill-editor>
      <div class="news-rel"  v-html="news_rel">
      </div>
      
    </div>
    <!-- <div class="vx-card" style="width: 680px; padding: 5px; margin-left:15px; font-size: 16px; font-family: '맑은고딕','Malgun Gothic'; text-align: justify; line-height: 25px;" v-html="contentView"></div> -->
    <div class="vx-col w-full md:w-1/4">
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>작성자: </p>
        </div>
        <div v-if="activeUserInfo.level >= 8" class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="작성자 선택" label="nickname" v-model="tmpWriter"
            :options="writer" @input="setWriter" :clearable="false"/>
        </div>
        <div v-if="activeUserInfo.level < 8" class="vx-col" style="width: 210px; padding: 10px">
          <div>{{ activeUserInfo.name }}</div>
        </div>
      </div>
      <div v-if="activeUserInfo.level >= 8">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기타 작성자: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" multiple placeholder="기타 작성자 선택" label="nickname" v-model="tmpWriter2"
             @input="setWriter2" :options="writer"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>기사 상태: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="기사 상태 선택" label="name" v-model="sendData.state"
            :options="states" :clearable="false"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>엠바고: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px;">
          <date-picker v-model="sendData.d_pass" type="datetime" :clearable="false"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>1차 카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label vs-category" multiple placeholder="1차 카테고리 선택" label="name" v-model="sendData.category1"
             @input="setCategory1" :closeOnSelect="false" :options="categories1"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>2차 카테고리: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label vs-category" multiple placeholder="2차 카테고리 선택" label="name" v-model="sendData.category2"
             @input="setCategory2" :closeOnSelect="false" :disabled="category2Disable" :options="categories2_view"/>
          <div class="write-new-ad-form">
              <vs-popup classContent="popup-example" title="2차 카테고리 선택" :active.sync="form.actives.category2">

                  <vs-button class="p-2" style="width: 95%" type="border" color="primary" @click="selectOkCategories2(); form.actives.category2 = false">완료</vs-button>

                  <div v-for="(item, idx) in categories2_view" v-bind:key="idx">
                      <vs-checkbox style="padding: 20px" v-model="form.data.category2.categories[idx]">{{ item.name }}</vs-checkbox>
                  </div>
              </vs-popup>
          </div>
        </div>
      </div>
      <div v-if="!reviewDisable">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>리뷰 평가: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="평가 점수" label="text" v-model="sendData.review_rate"
            :options="reviewOption"/>
        </div>
      </div>
      <div v-if="!siteDisable">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 4px">
          <p >현장기사: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <div style="height: 27px;"><toggle-button color="rgba(115, 103, 240, 1)" v-model="sendData.site" style="top: 2px"/></div>
        </div>
      </div>
      <div v-if="sendData.category1.findIndex(e => e.code == 10) > -1">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 4px">
          <p >포토셋 선택: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="포토셋 선택" label="photoSetName" v-model="sendData.photoSet"
            :options="photoSetArray"/>
        </div>
      </div>
      <div v-if="sendData.category1.findIndex(e => e.code == 10) > -1">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 4px">
          <p >포토셋 생성: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <vs-input class="inputx" placeholder="생성할 이름 입력" v-model="sendData.newPhotoSetName"/>
        </div>
      </div>
      <div v-if="sendData.category1.findIndex(e => e.code == 10) > -1">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 4px">
          <p >포토타입 선택: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <ul class="flex flex-wrap items-center">
            <li><vs-radio v-model="sendData.photoType" vs-value="1">뉴스</vs-radio></li>
            <li style="margin-right: 50px;"><vs-radio v-model="sendData.photoType" vs-value="2">스타</vs-radio></li>
          </ul>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>시리즈: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select class="vs-con-input-label" placeholder="시리즈 선택" label="name" v-model="sendData.series"
            @input="setSeries" :options="seriesArray"/>
          <!-- <v-select class="vs-con-input-label vs-category" multiple placeholder="시리즈 선택" label="name" v-model="sendData.series"
            :options="seriesArray" :closeOnSelect="false"/> -->
          <!-- <vs-select
            :success="sendData.series != '' && sendData.series != null"
            :warning="sendData.series == '' || sendData.series == null"
            placeholder="시리즈 선택" label="" v-model="sendData.series"
            style="height: 39px;"
            >
            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in seriesArray"/>
          </vs-select> -->
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>byline: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <vs-input class="inputx" placeholder="byline" v-model="sendData.byline"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>email: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <vs-input class="inputx" placeholder="email" @change="changeEmail" v-model="sendData.email"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 7px">
          <p>태그 :</p><p style="font-size: 12px;">(네이버 키워드)</p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <v-select ref="tagSelect" class="vs-con-input-label vs-tag" @option:created="tagFocusFlg = true;" @search:blur="setSelected" taggable multiple v-model="sendData.tag"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 5px">
          <p>썸네일 설정: </p>
        </div>
        <div class="vx-col flex-wrap" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="thumbnailFlg" style="top: 2px"/>
          <p v-if="thumbnailFlg"> 썸네일 위치: </p>
          <ul class="flex flex-wrap items-center" v-if="thumbnailFlg">
            <li><vs-radio v-model="thumbnailOption" vs-value="crop">자르기</vs-radio></li>
            <li style="margin-right: 50px;"><vs-radio v-model="thumbnailOption" vs-value="fit">전체포함</vs-radio></li>
            <!-- <li v-if="thumbnailFlg"><vs-slider v-model="thumbnail2" style="min-width: 190px;" text-fixed=% /> </li> -->
            <li v-if="thumbnailFlg"><vs-slider v-model="thumbnail" style="min-width: 190px;" text-fixed=% /> </li>
            <p v-if="thumbnailFlg">이미지 번호: </p>
            <li v-if="thumbnailFlg"><input type="text" style="width: 20px;" v-model="thumbnailImageNum"></li>
            <!-- <li v-if="thumbnailFlg"><input type="file" style="width: 200px;" @change="addFile"></li> -->
          </ul>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 5px">
        </div>
        <div class="vx-col flex-wrap" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="watermarkFlg" @change="watermarkToggle" style="top: 2px"/>
          <p>워터마크: </p>
          <ul class="flex flex-wrap items-center" v-if="watermarkFlg">
            <li><vs-radio v-model="sendData.watermark" vs-value="northwest">좌상단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="north">상단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="northeast">우상단</vs-radio>
            </li><br/>
            <li><vs-radio v-model="sendData.watermark" vs-value="southwest">좌하단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="south">하단</vs-radio>
            </li>
            <li><vs-radio v-model="sendData.watermark" vs-value="southeast">우하단</vs-radio>
            </li><br/>
            <p v-if="watermarkFlg">이미지 번호: </p>
            <li>
              <input type="text" style="width: 20px;" v-model="watermarkImageNum"> *숫자입력은 해당이미지만, 0은 모든이미지에 적용
            </li>
          </ul>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 5px">
          <p>포털전송: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <toggle-button color="rgba(115, 103, 240, 1)" v-model="portalFlg"/>
        </div>
      </div>
      <div>
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>전송할 포털: </p>
        </div>
        <div class="vx-col">
          <v-select class="vs-con-input-label vs-category" multiple placeholder="포털 선택" label="name" 
          v-model="sendData.portalAction" :closeOnSelect="false" :options="portals"/>
        </div>
      </div>
      <div v-if="(activeUserInfo.level >= 10 || activeUserInfo.permission.sns)">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>페이스북: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <md-checkbox v-model="sendData.sns.facebookFlg" />
        </div>
        <div class="vx-col">
          <textarea class="w-full" placeholder="페이스북 문구" v-model="sendData.sns.facebookTxt"/>
        </div>
      </div>
      <div v-if="(activeUserInfo.level >= 10 || activeUserInfo.permission.sns)">
        <div class="vx-col" style="width: 100px; padding: 0px; margin-top: 9px">
          <p>트위터: </p>
        </div>
        <div class="vx-col" style="width: 210px; padding: 0px">
          <md-checkbox v-model="sendData.sns.twitterFlg" />
        </div>
        <div class="vx-col">
          <textarea class="w-full" placeholder="트위터 문구" v-model="sendData.sns.twitterTxt"/>
        </div>
      </div>

      <div style="margin-top: 20px">
        <vs-button :disabled="previewDisable" color="success" type="filled" v-on:click="dataToArticle(function(){})" >미리보기 ({{sendButtonText}})</vs-button> 
      </div>
      <div style="margin-top: 20px; padding: 0px;">
        <textarea v-model="lockHistory" style="width: 100%; height: 200px; text-align: right;" />
      </div>

    </div>
          

    <!-- Start: 미리보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="미리보기" :active.sync="newsDetailModalActive">
        <div>
          <div class="news-detail">
            <div v-html="contentPreview"></div>
          </div>
          <div class="flex" style="justify-content: space-around; margin-bottom: 20px; padding: 20px 0; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc;">
            <!-- 미리보기: 
            <vs-radio color="success" v-model="previewType" vs-value="iPhone1">아이폰1(375)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="iPhone2">아이폰2(414)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="android1">안드로이드1(360)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="android2">안드로이드2(420)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="pc">PC</vs-radio> -->
            
            <vs-button color="blue" size="small" @click="previewType = 'iPhone1'" style="width: 25%" >iOS 미리보기</vs-button>
            <vs-button color="danger" size="small" @click="previewType = 'android1'" style="width: 25%" >Android 미리보기</vs-button>
            <vs-button color="grey" size="small" @click="previewType = 'pc'" style="width: 25%" >PC 미리보기</vs-button>
          </div>
          <div :class="previewType == 'pc' ? 'news-detail' : 
            previewType == 'iPhone1' ? 'newsDetailMobile iPhone1' : 
            previewType == 'iPhone2' ? 'newsDetailMobile iPhone2' :
            previewType == 'android1' ? 'newsDetailMobile android1' :
            previewType == 'android2' ? 'newsDetailMobile android2' :
            previewType == 'android3' ? 'newsDetailMobile android3' : ''">
            <div v-html="contentView"></div>
          </div>
        </div>
        <div style="position: sticky;max-height: 140px;bottom: -1rem;background: white;">
          <div class='news-rel'><h3>주요 뉴스</h3>
            <ul>
              <li v-for="(newsRel, idx) in sendData.rel" :key="idx">
                <a :href="'https://www.dailian.co.kr/news/view/' + newsRel.id" target="_blank" v-html="newsRel.b_sbj"></a>
              </li>
            </ul>
          </div>
          <vs-button style="float: right" id='sendButton' :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="sendDataToServer()" >{{sendButtonText}}</vs-button>
          <div class="flex" style="margin-top: 20px; justify-content: space-around;">
            <div><vs-radio v-model="relNewsListType" vs-value="newRanking" @change="changeRelType">자동배치</vs-radio></div>
            <div><vs-radio v-model="relNewsListType" vs-value="ranking" @change="changeRelType">많이본기사</vs-radio></div>
            <div><vs-radio v-model="relNewsListType" vs-value="search" @change="changeRelType">뉴스검색</vs-radio></div>
            <!-- <div><vs-radio v-model="relNewsListType" vs-value="recent" @change="changeRelType">최신기사</vs-radio></div>
            <div><vs-radio v-model="relNewsListType" vs-value="ranking" @change="changeRelType">많이본기사</vs-radio></div> -->
          </div>
          <div v-if="relNewsListType == 'search'" class="flex">
            <v-select style="width: 100px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="tmpCategory1"
              :options="categories1"/>
            <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="getNewsList()" style="display: inline-block;"/>
            <vs-button color="primary" type="border" v-on:click="getNewsList()" >검색</vs-button>
            <vs-button color="danger" type="border" v-on:click="swipeRelList()" >비우기</vs-button>
          </div>
          <div>
            <div v-for="(newsRel, idx) in newsRelListSearch[relNewsListType]" :key="idx">
              <md-checkbox @change="newsRelChange(newsRel, idx)" :value="newsRel" v-model="sendData.rel" >
                <b v-if="sendData.rel.find(e => e.id == newsRel.id)" v-html="newsRel.id + ' | ' + newsRel.d_pass + ' | ' + newsRel.b_sbj.replace(/<\/?strong>/g, '')"></b>
                <span v-else v-html="newsRel.id + ' | ' + newsRel.d_pass + ' | ' + newsRel.b_sbj.replace(/<\/?strong>/g, '')"></span>
              </md-checkbox>
            </div>
          </div>
          <div style="width: 100%; border: 1px solid black;"></div>
          <div>
            <div v-for="(newsRel, idx) in newsRelList" :key="idx">
              <md-checkbox @change="newsRelChange(newsRel, idx)" :value="newsRel" v-model="sendData.rel" >
                <b v-if="sendData.rel.find(e => e.id == newsRel.id)" v-html="newsRel.id + ' | ' + newsRel.d_pass + ' | ' + newsRel.b_sbj.replace(/<\/?strong>/g, '')"></b>
                <span v-else v-html="newsRel.id + ' | ' + newsRel.d_pass + ' | ' + newsRel.b_sbj.replace(/<\/?strong>/g, '')"></span>
              </md-checkbox>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 미리보기 팝업 -->
      
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
VueQuillEditor.Quill.register('modules/imageDrop', ImageDrop)
VueQuillEditor.Quill.register('modules/imageResize', ImageResize)
export default {
  data:()=>({
    form: {
      actives: {
        category1: false,
        category2: false
      },
      data: {
        category1: {
          categories: [],
          toString: "1차 카테고리 선택"
        },
        category2: {
          categories: [],
          toString: "2차 카테고리 선택"
        }
      }
    },
    sendData :{
      id: '',
      writer: '',
      mno: '33252',
      mno2: '',
      another_writer: '',
      email: '',
      text: '',
      summary: '',
      Images: [],
      icon: '',
      mainTitle: '',
      portalTitle: '',
      subTitle: '', 
      state: {'code': ''}, 
      site: false,
      type: '1',
      watermark: '',
      thumbnail: '',
      category1: [],
      category2: [],
      series: {'code': ''},
      review_rate: {'rate': ''},
      byline: '',
      d_pass: '',
      g_money: 0,
      s_money: 0,
      name: '',
      tailname: '',
      tag: [],
      titleTags: [],
      rel: [],
      portalAction: [],
      out_site: '',
      photoType: "1",
      photoSet: null,
      newPhotoSetName: '',
      sns: {},
    },
    previewType: 'android1',
    portalFlg: true,
    watermarkFlg: false,
    watermarkImageNum: 0,
    thumbnailFlg: false,
    thumbnailOption: 'crop',
    thumbnailImageNum: 1,
    thumbnail: 50,
    thumbnail2: [0,100],
    photoData: [],
    pastState: '9',
    lockHistory: '',
    searchText: '',
    newsRelList: [],
    newsRelListSearch: {'search':[], 'recent': [], 'ranking': [], 'newRanking': []},
    tmpCategory1: [],
    tmpCategory2: [],
    tmpWriter: {},
    tmpWriter2: [],
    writer: [],
    types: [],
    states: [],
    portals: [],
    categories1: [],
    categories1_ok: false,
    categories2: [],
    categories2_view: [],
    category2Disable: true,
    seriesArray: [],
    photoSetArray: [],
    lastArticleTime: moment().add(-1, 'day'),
    snsKeyword: ['온라인 커뮤니티', '해외 커뮤니티', '보배드림', '디시인사이드', '네이트 판', '블라인드'],
    forceCategoryList: [{keyword: '박민영 사내 이사', category: '연예'}],
    sendButtonDisable: true,
    sendButtonText: '기사 저장',
    previewDisable: true,
    textcount: 0,
    papers:0,    
    content: '',
    contentView: '',
    contentPreview: '',
    news_rel: '',
    tmp_rel: [],
    relNewsListType: 'ranking',
    siteDisable: true,
    reviewDisable: true,
    tooltipDisable: false,
    tagFocusFlg: false,
    reviewOption: [{rate: 5, text: '★★★★★'},
                   {rate: 4.5, text: '★★★★☆'},
                   {rate: 4, text: '★★★★'},
                   {rate: 3.5, text: '★★★☆'},
                   {rate: 3, text: '★★★'},
                   {rate: 2.5, text: '★★☆'},
                   {rate: 2, text: '★★'},
                   {rate: 1.5, text: '★☆'},
                   {rate: 1, text: '★'},
                   {rate: 0.5, text: '☆'}],
    newsDetailModalActive: false,
    editorOption: {
      modules: {
        // toolbar: '#toolbar',//[['bold', 'italic', 'underline'], ['blockquote'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }], ['link', 'image', 'video']],
        toolbar: {
          container: '#toolbar',
          // container:[
          //   ['bold', 'italic', 'underline'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }], ['link', 'image', 'video'],
          //   [{'customMacro': '매크로'}], [{'customSpecial': ['ㅁ', 'ㅇ', 'ㅎ']}]
          // ],
          handlers: {
            "customSpecial": function (value) { 
              if (value || value == '') {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
            // "customMacro": function (value) { 
            //   if (value) {
            //     console.log(value, this)
            //     const cursorPosition = this.quill.getSelection().index;
            //     this.quill.insertText(cursorPosition, value);
            //     this.quill.setSelection(cursorPosition + value.length);
            //   }
            // }
          }
        },
        imageDrop: true,
        imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption2: {
      modules: {
        toolbar: {
          container: '#toolbar2',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption3: {
      modules: {
        toolbar: {
          container: '#toolbar3',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    editorOption4: {
      modules: {
        toolbar: {
          container: '#toolbar4',
          handlers: {
            "customSpecial": function (value) { 
              if (value) {
                console.log(value, this)
                const cursorPosition = this.quill.getSelection().index;
                this.quill.insertText(cursorPosition, value);
                this.quill.setSelection(cursorPosition + value.length);
              }
            },
          }
        },
      },
      theme: 'snow',
      placeholder: '내용을 입력하세요.'
    },
    prevRoute: '',
    initImage: false,
    timeFlg: false,
    autoSaveFlg: false,
    autoSaved: false,
    lockFlg: true,
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // console.log(from)
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    if(this.autoSaveFlg && confirm('기사를 저장하시겠습니까?')){
      this.autoSaved = true;
      this.dataToArticle((data) =>{
        if(!data){
          this.newsDetailModalActive = false;
          this.sendDataToServer();
          setTimeout(() => { next(); }, 100);
        }
      });
    }else{
      this.newsDetailModalActive = false;
      setTimeout(() => { next(); }, 100);
    }
    if(this.lockFlg){
      console.log('open:', this.lockFlg)
      this.$http.post('/api/writeArticles/lock', {id: this.sendData.id, name: this.$store.state.AppActiveUser.name, mno: this.$store.state.AppActiveUser.id, lock: 'open'})
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      this.created();
    },
    'sendData.state': function() {
      if(this.sendData.state == this.states.find(e => e.code == '1')) this.sendButtonText = '기사 전송'
      else  this.sendButtonText = '기사 저장'
    }

  },
  components: {
    LocalQuillEditor: VueQuillEditor.quillEditor,
    DatePicker,
  },
  methods: {
    changeRelType(){
      // console.log(this.relNewsListType);
      if(this.relNewsListType == "newRanking"){
        this.swipeRelList();
      }else if(this.sendData.rel.length == 0){
        this.sendData.rel = this.tmp_rel;
      } 
      this.setNewsRelList(this.relNewsListType);
    },
    newsRelChange(){
      // console.log(newsRel, this.sendData.rel)
      // if(newsRel.flg){ this.sendData.rel = this.sendData.rel.filter(e => e.id != newsRel.id) }
      // else{ this.sendData.rel.push(newsRel); }
      if(this.sendData.rel.length > 5){
        this.sendData.rel.splice(5);
        // this.newsRelList[idx].flg = false;
        // console.log(this.newsRelList)
        this.$vs.notify({
          title:'Error',
          text: '관련기사는 최대 5개까지 입력가능합니다.',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'})
      }
    },
    swipeRelList(){
      this.sendData.rel = [];
    },
    setNewsRelList(type){
      const thisIns = this;
      let newRel = thisIns.sendData.rel.slice();
      for(var i in thisIns.sendData.rel){
        let tmpRel = thisIns.newsRelListSearch[type].findIndex(e => e.id == thisIns.sendData.rel[i].id);
        if( tmpRel > -1 ){
          newRel[i] = thisIns.newsRelListSearch[type][tmpRel];
        }
      }
      thisIns.sendData.rel = newRel;
      // thisIns.tmp_rel = newRel;
    },
    getRankingNewsList(){
      let type = 'ranking';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getNewsList/ranking', {category1: 0})
        .then(function (response) {
          let getData = response.data;
          getData = getData.slice(0,5)
          console.log('ranking: ', getData)
          thisIns.newsRelListSearch[type] = getData.filter(e => thisIns.newsRelList.findIndex(e2 => e2.id == e.id) < 0);
          if(!thisIns.sendData.rel || thisIns.sendData.rel.length == 0){
            thisIns.sendData.rel = getData;
          }
          thisIns.setNewsRelList(type);
          console.log(thisIns.newsRelListSearch)
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    getNewsList(type='search') {
      let endDate = moment();
      if(moment(this.sendData.d_pass).format('X') > endDate.format('X')){
        endDate = moment(this.sendData.d_pass);
      }
      let data = {
        startRow: 0,
        itemsPerPage: 20,
        category1: this.tmpCategory1,
        searchText: this.searchText,
        startDate: type=="search" ? moment().subtract(6, 'month') : moment().subtract(1, 'week'),
        endDate: endDate.add(10, 'm'),
        department: '',
        level: this.activeUserInfo.level,
        id: this.activeUserInfo.id,
        relNews: true,
      }
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      let getData = [];
      this.$http.post('/api/getNewsList', data)
        .then(function (response) {
          getData = response.data[1];
          thisIns.newsRelListSearch[type] = getData.filter(e => thisIns.newsRelList.findIndex(e2 => e2.id == e.id) < 0);
          thisIns.setNewsRelList(type);
          // console.log(thisIns.newsRelListSearch)
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    selectOkCategories1() {
      
      this.categories1 = this.$store.state.categories1;

      this.sendData.category1 = []
      // for(var idx in this.form.data.category1.categories) {
      //   console.log('idx:', idx)
      //   if(this.form.data.category1.categories[idx]) {
      //     this.sendData.category1.push({name: this.categories1[idx].name, code: this.categories1[idx].code})
      //   }
      // }

      for(var idx in this.categories1) {
        if(this.form.data.category1.categories[idx]) {
          this.sendData.category1.push({name: this.categories1[idx].name, code: this.categories1[idx].code})
        }
      }

      this.form.data.category1.toString = ``
      // for(var idx in this.form.data.category1.categories) {
      //   if(this.form.data.category1.categories[idx]) {

      //     if(this.form.data.category1.toString == ``) {

      //       this.form.data.category1.toString += `${this.categories1[idx].name}`
      //     }
      //     else {

      //       this.form.data.category1.toString += `, ${this.categories1[idx].name}`
      //     }
      //   }
      // }
      for(idx in this.categories1) {
        if(this.form.data.category1.categories[idx]) {

          if(this.form.data.category1.toString == ``) {

            this.form.data.category1.toString += `${this.categories1[idx].name}`
          }
          else {

            this.form.data.category1.toString += `, ${this.categories1[idx].name}`
          }
        }
      }

      var dataToAvoidBug = []
      for(idx in this.sendData.category1) {

        dataToAvoidBug.push(this.sendData.category1[idx])
      }

      this.setCategory1(dataToAvoidBug)
    },
    selectOkCategories2() {

      this.sendData.category2 = []
      
      for(var idx in this.categories2_view) {

        if(this.form.data.category2.categories[idx]) {
          this.sendData.category2.push({name: this.categories2_view[idx].name, code: this.categories2_view[idx].code})
        }
      }

      this.form.data.category2.toString = ``
      for(idx in this.categories2_view) {
        if(this.form.data.category2.categories[idx]) {

          if(this.form.data.category2.toString == "2차 카테고리 선택" || this.form.data.category2.toString == ``) {

            this.form.data.category2.toString += `${this.categories2_view[idx].name}`
          }
          else {

            this.form.data.category2.toString += `, ${this.categories2_view[idx].name}`
          }
        }
      }

      var dataToAvoidBug = []
      for(idx in this.sendData.category2) {

        dataToAvoidBug.push(this.sendData.category2[idx])
      }

      this.setCategory2(dataToAvoidBug)
    },
    sendDataToServer(){ //<-----------------------------------------------------------------------------------------Server 보내는 함수
      // const re = /(\[|<|\()(속보|인사|부고|컨콜|\d보|종합)(\]|>|\))/;
      // const timeSetting = 10;
      // console.log('senddata: ', this.sendData, !this.sendData.mainTitle.match(re), (this.sendData.state.code == 1 || this.sendData.state.code == 2) && 
      //         this.pastState != 1 && !this.sendData.mainTitle.match(re) &&
      //         this.sendData.category1.findIndex(e => e.code == 10) == -1 &&
      //         moment(this.sendData.d_pass).add(-timeSetting, 'minute') < this.lastArticleTime);
      // return;

      if(this.sendData.state.code == 1 && moment(this.sendData.d_pass).format('X') > moment().format('X')){
          // this.$vs.notify({
          //   title:'Error',
          //   text: '엠바고 시간이 되지 않아 송고완료할 수 없습니다.',
          //   color:'danger',
          //   iconPack: 'feather',
          //   icon:'icon-alert-circle'})
          this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: `Error`,
              text: '엠바고 시간이 되지 않아 송고완료할 수 없습니다.',
          })
          return;
      }else if(this.sendData.state.code == 2 && moment(this.sendData.d_pass).format('X') < moment().format('X')){
          // this.$vs.notify({
          //   title:'Error',
          //   text: '엠바고 시간이 이미 지났습니다. 시간을 확인해주세요.',
          //   color:'danger',
          //   iconPack: 'feather',
          //   icon:'icon-alert-circle'})
          this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: `Error`,
              text: '엠바고 시간이 이미 지났습니다. 시간을 확인해주세요.',
          })
          return;
      // }else if((this.sendData.state.code == 1 || this.sendData.state.code == 2) && 
      //         this.pastState != 1 && !this.sendData.mainTitle.match(re) &&
      //         this.sendData.category1.findIndex(e => e.code == 10) == -1 &&
      //         moment(this.sendData.d_pass).add(-timeSetting, 'minute') < this.lastArticleTime){
      //     this.$vs.dialog({
      //         type: 'alert',
      //         color: 'danger',
      //         title: `Error`,
      //         text: `이전 기사 송고 후 시간이 얼마 지나지 않았습니다. ${timeSetting}분 이후에 송고하거나 송고예약을 해주세요.`,
      //     })
      //     return;
      }
      if(this.relNewsListType != "newRanking" && (!this.sendData.rel || this.sendData.rel.length < 2)){
          this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: `Error`,
              text: '관련기사를 2개 이상 등록해주세요.',
          })
          return;
      }
      this.$vs.loading({
        scale: 1.5
      });
      // this.sendData.mainTitle = this.sendData.mainTitle;
      // this.sendData.portalAction = [];
      if(this.portalFlg){
        let tmpOutSite = this.sendData.out_site.split("|");
        console.log('out_site: ', tmpOutSite);
        for(var i = 0; i<this.sendData.portalAction.length; i++){
          // this.sendData.portalAction.push(JSON.parse(JSON.stringify(this.portals[i])));
          if(tmpOutSite.find(e => e.toLowerCase() == this.sendData.portalAction[i].name.toLowerCase() || e == this.sendData.portalAction[i].oldName )) this.sendData.portalAction[i]['action'] = "U";
          else this.sendData.portalAction[i]['action'] = "I";
        }
        console.log('portalAction: ', this.sendData.portalAction);
      }else{
        this.sendData.portalAction = [];
      }
      // let sqareThumbnail = this.sendData.category1.findIndex(e => e.code == 11);
      // if(this.thumbnailFlg){
      //   this.sendData.thumbnail = sqareThumbnail + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      // }else{
      //   this.sendData.thumbnail = sqareThumbnail + ';;';
      // }
      if(this.thumbnailFlg){
        this.sendData.thumbnail = this.thumbnail2 + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      }else{
        this.sendData.thumbnail = this.thumbnail2 + ';;;';
      }
      if(this.sendData.portalTitle == ''){
        this.sendData.portalTitle = this.sendData.mainTitle;
      }
      const thisIns = this;
      // console.log('this.sendData:', this.sendData, this.sendData.subTitle.split('</p><p>').join('\n').split('<p>').join('').split('</p>').join(''))
      this.$http.post('/api/writeArticles', this.sendData)
        .then(function () {
          // console.log(response, thisIns.$router, thisIns.prevRoute)
          thisIns.newsDetailModalActive = false;
          thisIns.$vs.loading.close(); 
          var newPath = thisIns.prevRoute.path;
          var newQuery = thisIns.prevRoute.query;
          if(!thisIns.prevRoute.path || thisIns.prevRoute.path.includes('login')) {
            newPath = '/newsList';
            newQuery = '';
          }
          if(!thisIns.autoSaved){
            thisIns.autoSaveFlg = false;
            thisIns.$router.push({path: newPath, query: newQuery });
            // if(thisIns.prevRoute.path == '/writeArticles') thisIns.$router.go(-2);
            // else thisIns.$router.back();
            // thisIns.$router.push({path: '/newsList'});
            alert('기사를 저장하였습니다.')
          }
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    onEditorChange(event) {
      if(!this.initImage && this.timeFlg) this.autoSaveFlg = true;
      this.sendButtonDisable = true;
      
      let lines = event.quill.getLines();
      const colorList = ['rgb(255, 255, 255)', 'rgb(187, 187, 187)', 'rgb(136, 136, 136)', 'rgb(68, 68, 68)',
                          'rgb(230, 0, 0)', 'rgb(250, 204, 204)', 'rgb(240, 102, 102)', 'rgb(161, 0, 0)', 'rgb(92, 0, 0)', 
                          'rgb(255, 153, 0)', 'rgb(255, 235, 204)', 'rgb(255, 194, 102)', 'rgb(178, 107, 0)', 'rgb(102, 61, 0)', 
                          'rgb(255, 255, 0)', 'rgb(255, 255, 204)', 'rgb(255, 255, 102)', 'rgb(178, 178, 0)', 'rgb(102, 102, 0)', 
                          'rgb(0, 138, 0)', 'rgb(204, 232, 204)', 'rgb(102, 185, 102)', 'rgb(0, 97, 0)', 'rgb(0, 55, 0)', 
                          'rgb(0, 102, 204)', 'rgb(204, 224, 245)', 'rgb(102, 163, 224)', 'rgb(0, 71, 178)', 'rgb(0, 41, 102)', 
                          'rgb(153, 51, 255)', 'rgb(235, 214, 255)', 'rgb(194, 133, 255)', 'rgb(107, 36, 178)', 'rgb(61, 20, 102)', ];
      for(const line of lines){
        for(const lineChild of line.domNode.childNodes){
          console.log('color: ', lineChild.style)
          // if(lineChild.style && (lineChild.style.color.indexOf("rgb(98, 98, 98)") > -1 
          //                       || lineChild.style.color.indexOf("rgb(119, 119, 119)") > -1
          //                       || lineChild.style.color.indexOf("rgb(0, 0, 0)") > -1
          //                       || lineChild.style.color.indexOf("rgb(20, 20, 20)") > -1
          //                       || lineChild.style.color.indexOf("rgb(17, 17, 17)") > -1
          //                       || lineChild.style.color.indexOf("black") > -1)){
          if(lineChild.style && lineChild.style.color && colorList.findIndex(e => lineChild.style.color.indexOf(e) > -1) < 0){
            lineChild.style.color = "";
          }
          if(lineChild.style && lineChild.style.backgroundColor){
            lineChild.style.backgroundColor = "";
          }
          if(lineChild.style && lineChild.style.background){
            lineChild.style.background = "";
          }
        }
      }
      // console.log(this.sendData.mainTitle.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*)>/g, ""), event.quill,event.quill.getLine(0));
      if(event.quill == this.$refs.quillEditor_b_sbj.quill || event.quill == this.$refs.quillEditor_p_sbj.quill){
        if(event.quill.getLines().length > 1) {
          event.quill.setContents(event.quill.getLines()[0].cache.delta);
          // event.quill.deleteText(event.quill.getLines()[0].length,1);
        }else if(event.quill.root.innerHTML.match('</?(strong[^>]*)>')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/<\/?(strong[^>]*)>/g,'');
        }
      }
      else if(this.$refs.quillEditor_s_sbj && event.quill == this.$refs.quillEditor_s_sbj.quill){
        if(event.quill.getLines().length > 4) {
          event.quill.setContents(event.quill.getLines()[0].cache.delta
                                  .concat(event.quill.getLines()[1].cache.delta)
                                  .concat(event.quill.getLines()[2].cache.delta)
                                  .concat(event.quill.getLines()[3].cache.delta));
        }else if(event.quill.root.innerHTML.match('background-color:[^;]*;')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/background-color:[^;]*;/g,'');
        }else if(event.quill.root.innerHTML.match('</?(strong[^>]*)>')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/<\/?(strong[^>]*)>/g,'');
        }
      }
      else if(event.quill == this.$refs.quillEditor_descr.quill) {
        if(event.quill.root.innerHTML.match('&nbsp;')){
          event.quill.root.innerHTML = event.quill.root.innerHTML.replace(/&nbsp;\s?&nbsp;/g,'</p><p><br></p><p>').replace(/<p>\s?&nbsp;\s?<\/p>/g,'<p><br></p>').replace(/&nbsp;/g,'');
        }
        this.countCharacter(event);
      }
    },
    setTitleTag(){
      console.log(this.$refs.titleTagSelect.$el.querySelector('input'));
      if(this.tagFocusFlg) this.$refs.titleTagSelect.$el.querySelector('input').focus();
      this.tagFocusFlg = false;
      this.sendData.titleTags.splice(3)
    },
    setSelected(){
      console.log(this.$refs.tagSelect.$el.querySelector('input'));
      if(this.tagFocusFlg) this.$refs.tagSelect.$el.querySelector('input').focus();
      this.tagFocusFlg = false;
    },
    loadData(data){
      var textTemp = data;
      var root="https://cdnimage.dailian.co.kr/news/";
      var i = 1;
      var tmp;
      var imgText1 = '<p><img src="';
      var imgText2 = '" width="';
      var imgText3 = '" height="';
      var imgText4 = '"></p>';
      while (textTemp.match('<@IMG'+i+'>')){
        tmp = textTemp.match('<@IMG'+i+'>')
        textTemp = textTemp.replace(tmp[0],imgText1 + root + this.sendData.images[i-1].img + imgText2 + this.sendData.images[i-1].width + imgText3 + imgText4 + "<p>" + this.sendData.images[i-1].img_c + "</p>");
        i++;
      }
      this.initImage = true;
      return textTemp;
    },
    dataToArticle(cb){
      if(this.sendData.mainTitle == '' && this.sendData.portalTitle){ this.sendData.mainTitle = this.sendData.portalTitle; }
      else if(this.sendData.portalTitle == '' && this.sendData.mainTitle){ this.sendData.portalTitle = this.sendData.mainTitle; }
      if(this.sendData.state.code == 1 || this.sendData.state.code == 2){
        if(this.sendData.category1.findIndex(e => e.code == 3) > -1 && 
          this.snsKeyword.find(e => this.sendData.mainTitle.indexOf(e) > -1 || this.content.indexOf(e) > -1)){
            this.$vs.dialog({
                type: 'alert',
                color: 'warning',
                title: `위험`,
                text: '기사를 사회 섹션으로 분류시 네이버 판 편집 배치에서 제외될 수 있습니다.',
            })
        }
        // for(const fc of this.forceCategoryList){
        //   // console.log(fc, this.sendData.category1.findIndex(e => e.code == fc.category1), 
        //   // this.sendData.mainTitle.indexOf(fc.keyword), this.content.indexOf(fc.keyword),
        //   // this.sendData.tag.findIndex(e => e.indexOf(fc.keyword) > -1))
        //   if(this.sendData.category1.findIndex(e => e.code != fc.category1) > -1 && 
        //     (this.sendData.mainTitle.indexOf(fc.keyword) > -1 || this.content.indexOf(fc.keyword) > -1 
        //     // || this.sendData.tag.findIndex(e => e.indexOf(fc.keyword) > -1) > -1
        //     )){
        //       this.$vs.dialog({
        //           type: 'alert',
        //           color: 'warning',
        //           title: `위험`,
        //           text: `기사의 카테고리 설정을 ${this.categories1.find(e => e.code == fc.category1).name}로 변경하는걸 고려해주세요`,
        //       })
        //   }
        // }
      }
      this.sendData.images = [];
      console.log('content: ', this.content, this.sendButtonDisable)
      var textTemp = this.content.replace(/<p> ?&nbsp; ?<\/p>/g, '<p><br></p>').replace(/&nbsp;/g,'').replace(/&quot;/g,'').replace(/(border-top|background):(\s?url\(data:image\/svg\+xml;)?[^;]*;/g,'');
      var tmp, tmp2, tmp3, tmp4 =[]; 
      var tmpWidth, i=1;
      // textTemp = textTemp.replace(/<\/pre[^>]*>/, "</div>").replace(/<pre[^>]*>/, "<div style='background-color: inherit;color: inherit;margin: 17px 0;padding: 15px 0;border-top: 1px solid #444446;border-bottom: 1px solid #ebebeb;font-size: 20px;'>");
      textTemp = textTemp.replace(/\s*<\/pre[^>]*>/g, "</pre>");
      textTemp = textTemp.replace(/<\/blockquote>\s*<blockquote[^>]*>/g, "<br>");
      // tmp2 = textTemp.match(/<\/blockquote><blockquote>/g);
      // if(tmp2){
      //   for(var j in tmp2){
      //     textTemp = textTemp.replace(tmp2[j], "<br>");
      //   }
      // }
      tmp2 = textTemp.match(/<iframe.*?>(.*?)<\/iframe>/g)
      if(tmp2){
        console.log('iframe: ', tmp2)
        for(var j in tmp2){
          let matchData = tmp2[j].match(/(src=")([^"]*(autoPlay=true))(")/);
          console.log("match: ", matchData)
          if(matchData && matchData[3]){
            textTemp = textTemp.replace(tmp2[j], tmp2[j].replace('src=', 'style="max-width:100%; width: 640px; height: 360px; display: block; margin: 0 auto;" src=').replace(/(autoPlay=true)/g, "").replace(/(src=")([^"]*)(")/, '$1$2&autoplay=1&mute=1$3'))
            // textTemp = textTemp.replace(tmp2[j], tmp2[j].replace('src=', 'style="max-width:100%; width: 640px; height: 360px;" src=').replace(/autoPlay=false/, 'autoPlay=true'));
          }else if(tmp2[j].match(/\?/)){
            textTemp = textTemp.replace(tmp2[j], tmp2[j].replace('src=', 'style="max-width:100%; width: 640px; height: 360px; display: block; margin: 0 auto;" src=').replace(/(src=")([^"]*)(")/, '$1$2&autoplay=1&mute=1$3'));
          }else{
            textTemp = textTemp.replace(tmp2[j], tmp2[j].replace('src=', 'style="max-width:100%; width: 640px; height: 360px; display: block; margin: 0 auto;" src=').replace(/(src=")([^"]*)(")/, '$1$2?autoplay=1&mute=1$3'));
          }
          // textTemp = textTemp.replace(tmp2[j], tmp2[j].slice(0, tmp2[j].indexOf('src=')) + `style="max-width:100%; width: 640px; height: 360px;" ` + tmp2[j].slice(tmp2[j].indexOf('src=')));
        }
      }
      while(textTemp.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)){
        tmp = textTemp.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)
        console.log('imgtag: ', tmp)
        // this.sendData.arrayBase64.push(tmp[1]);
        tmp3 = tmp[0].match("display:?([^;]+)[;]")
        if(tmp3)
        {
          console.log('dddd',tmp3)
          if(tmp3[1] == ' block'){
            console.log('block')
          }else if(tmp3[1] == ' inline'){
            tmp3 = tmp[0].match("float:?([^;]+)[;]")
            console.log(tmp3[1])
          }
        }
        if(tmp3){
          if(tmp3[1] == ' block') tmp3 = "C";
          else if(tmp3[1] == ' left') tmp3 = "L";
          else if(tmp3[1] == ' right') tmp3 = "R";
        }
        else tmp3 = "C";

        console.log('result_tmp3:', tmp3)
        // this.sendData.arrayImageAlign.push(tmp3);
        tmpWidth = tmp[0].match(/width="?([^"]+)["]/)
        if(tmpWidth){
          if(tmpWidth[1] > this.$store.state.maxWidth) tmpWidth = this.$store.state.maxWidth;
          else tmpWidth = tmpWidth[1];
        }else tmpWidth = "";
        // this.sendData.arrayImageWidth.push(tmpWidth);

        var imgTag = '<@IMG'+i+'>';
        var tmpCaption = '';
        textTemp = textTemp.replace(tmp[0],imgTag);
        tmp4 = textTemp.match(imgTag+"(<span class=\"ql-cursor\">\uFEFF</span>)?(</p><p[^>]*>)?(.*?)</p>")
        if (tmp4){
          console.log('tmp4: ', tmp4)
          if(tmp4[3].match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/)){
            console.log(tmp4[3], tmp4[3].indexOf('<img'));
            textTemp = textTemp.replace(tmp4[0].substring(0,tmp4[0].indexOf('<img')),imgTag + "</p>");
            tmpCaption = tmp4[3].substring(0,tmp4[3].indexOf('<img'));
          }else if(tmp4[3].match(/<\/a>/)) {
            textTemp = textTemp.replace(tmp4[0],imgTag + "</a>" + "</p>");
            tmpCaption = "";
          }else {
            textTemp = textTemp.replace(tmp4[0],imgTag + "</p>");
            tmpCaption = tmp4[3].replace(/<\/?([a-z]+[^>]*)>/g,'');
            // this.sendData.arrayImageText.push(tmp4[1]);
          }
        }else tmpCaption = '';//this.sendData.arrayImageText.push('');
        var pno = 0;
        var photoIndex = -1;
        console.log('this.photoData:', this.photoData)
        if((photoIndex = this.photoData.findIndex(e => tmp[1].includes(e.img))) >= 0){
          pno = this.photoData[photoIndex].pno;
        }
        console.log('pno:', pno, tmp[1])
        this.sendData.images.push({'img': tmp[1], 'img_c': tmpCaption, 'width': tmpWidth, 'align': tmp3, 'pno': pno})
        i++;
      }
      // console.log(textTemp.replace(/<\/?([a-z]+[^>]*|@IMG[^>])>/g, ""))
      this.sendData.text = textTemp.replace(/<(|p[^>]*)> ?<\/p>/g, "");
      this.sendData.summary = textTemp.replace(/<\/?([a-z]+[^>]*|@IMG[^>]*)>/g, "").substr(0,300);
      if(textTemp.replace(/<\/?([a-z]+[^>]*|@IMG[^>]*)>/g, "").length > 300) this.sendData.summary += "…"
      // this.sendData.mainTitle = this.sendData.mainTitle.replace(/background-color:[^;]*;|<\/?(h[^>]*|p[^>]*|strong[^>]*)>/g, "").substring(0,99);
      this.sendData.subTitle = this.sendData.subTitle.replace(/background-color:[^;]*;/g, '').split('<p><br></p>').join('').substring(0,499);
      // console.log('mainTitle: ', this.sendData.mainTitle, 'subTitle: ', this.sendData.subTitle);
      // console.log(this.$refs.quillEditor_descr.quill.getText().substr(0,50), this.$refs.quillEditor_descr.quill.getText().length);
      // if(this.$refs.quillEditor_descr.quill.getText().length > 52) this.sendData.summary = this.$refs.quillEditor_descr.quill.getText().substr(0,51) + '…';
      // else this.sendData.summary = this.$refs.quillEditor_descr.quill.getText();
      this.descrToArticle(textTemp, this.sendData.images, this.sendData.mainTitle, this.sendData.subTitle, 
                          moment(this.sendData.d_pass).format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm'), this.sendData.byline.replace('데일리안 = ', '').replace('데일리안 스포츠 = ',''), 
                          this.sendData.email, this.sendData.review_rate, (article, preview)=>{
        this.contentView = article;
        this.contentPreview = preview;
        // console.log('미리보기', this.contentView)
        let alertText = '';
        if(this.sendData.mainTitle == '' && this.sendData.portalTitle == ''){
          alertText += '제목, ';
        }
        if(this.sendData.category1.length < 1) alertText += '1차 카테고리, ';
        if(!this.category2Disable && this.sendData.category2.length < 1) alertText += '2차 카테고리, ';
        if(!this.sendData.tag || this.sendData.tag.length < 2) alertText += '2개 이상의 태그, ';
        if(alertText != ''){
          let alertText2 = '';
          if(alertText == '제목, ') alertText2 = '을 입력해주세요!'
          else alertText2 = '를 입력해주세요!'
          
          // this.$vs.dialog({
          //     type: 'alert',
          //     color: 'warning',
          //     title: `정보 입력 확인`,
          //     text: alertText.substring(0,alertText.length-2) + alertText2,
          // })
          alert(alertText.substring(0,alertText.length-2) + alertText2);
          this.sendButtonDisable = true;
        }else {
          this.sendButtonDisable = false;
          // if(this.sendData.rel && this.sendData.rel.length > 1){
          //   this.sendButtonDisable = false;
          // }else{
          //   this.sendButtonDisable = true;
          // }
        }
        this.getRankingNewsList();
        this.searchText = '';
        this.getNewsList('recent');
        cb(this.sendButtonDisable);
      });
    },
    descrToArticle(descr, imgOption, mainTitle, subTitle, d_pass, d_modi, name, email, review_rate, cb){
      this.$vs.loading({
        scale: 1.5
      });
      console.log('텍스트: ',descr)
      const thisIns = this;
      var root="/news/";
      const maxWidth = this.$store.state.maxWidth;
      let sqareThumbnail = this.sendData.category1.findIndex(e => e.code == 11);
      // if(this.thumbnailFlg){
      //   this.sendData.thumbnail = sqareThumbnail + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      // }else{
      //   this.sendData.thumbnail = sqareThumbnail + ';;';
      // }
      if(this.thumbnailFlg){
        this.sendData.thumbnail = this.thumbnail2 + ';' + this.thumbnail + ';' + this.thumbnailOption + ';' + this.thumbnailImageNum;
      }else{
        this.sendData.thumbnail = this.thumbnail2 + ';;;';
      }
      this.activeUserInfo.watermark = this.sendData.watermark;
      console.log('sqareThumbnail:', sqareThumbnail, this.sendData.thumbnail, this.sendData.icon)
      this.$http.post('/api/writeArticles/preview', {'id': this.sendData.id, 'images': imgOption, 'thumbnail': this.sendData.thumbnail, 'watermark': this.sendData.watermark, 'wartermarkImageNum': this.watermarkImageNum, 'tag': this.sendData.tag, icon: this.sendData.icon })
        .then(function (data) {
          console.log(data)
          thisIns.newsDetailModalActive = true;
          // thisIns.newsRelList = [];
          // for(const tag of data.data.tags){
          //   thisIns.newsRelList.push({id: tag.id, b_sbj: tag.b_sbj});
          // }
          let thumbnailImageNum = parseInt(thisIns.thumbnailImageNum);
          if(!thumbnailImageNum || thumbnailImageNum > imgOption.length) { thumbnailImageNum = 1; }
          thisIns.newsRelList = data.data.tags;
          // console.log(thisIns.newsRelList)
          if(!thisIns.sendData.rel.length){ thisIns.sendData.rel = thisIns.newsRelList.slice(0,5); }
          else{ 
            for(var i in thisIns.sendData.rel){
              let tmpRel = thisIns.newsRelList.findIndex(e => e.id == thisIns.sendData.rel[i].id);
              if( tmpRel > -1 ){
                thisIns.sendData.rel[i] = thisIns.newsRelList[tmpRel];
              }
            }
          }
          // var rel_news = thisIns.newsRelList.slice(0,5);
          thisIns.sendData.images = data.data.images;
          thisIns.sendData.icon = data.data.icon;
          imgOption = data.data.images;
          var imgAlign = [];
          for(i=0;i<imgOption.length;i++){
            if(imgOption[i].align == 'C') imgAlign[i] = '';
            else if(imgOption[i].align == 'L') imgAlign[i] = 'margin: 0 15px; float: left;';
            else if(imgOption[i].align == 'R') imgAlign[i] = 'margin: 0 15px; float: right';
          }
          let article;
          var textTemp = "";
          let preview = "";
          if(imgOption.length){
            // thisIns.sendData.icon = imgOption[thumbnailImageNum-1].img.substring(0, imgOption[thumbnailImageNum-1].img.indexOf(`m_${thumbnailImageNum}`)) + "c.jpg"
            preview = "<div style='margin-bottom: 20px;'><div><span style='margin-left: 150px;'>일반</span><span style='margin-left: 350px;'>메인 배치</span></div><img src=" + root + "icon/" + thisIns.sendData.icon + ">"
            + "<img src=" + root + "icon/" + thisIns.sendData.icon.replace("_c", "_c3") + " style='margin-left: 20px; width:400px;'></div>";
          }
          textTemp += "<h2 class='title'>" + mainTitle + "</h2><div class='information'><ul><li>입력 " 
                          + d_pass + "</li><li>수정 " + d_modi + "</li><li>" + name + "</li></ul></div><div class='article'>";
          if(subTitle){
            textTemp += "<div class='inner-subtitle'>" + subTitle + "</div>";
          }
          textTemp += descr + "</div>";
          // if(rel_news.length){
          //   textTemp += "<div class='news-rel'><h3>관련기사</h3><ul>"
          //   for(var i = 0; i<rel_news.length;i++){
          //     textTemp += "<li><a href='//www.dailian.co.kr/news/view/" + rel_news[i].id + "' target='_blank'>" + rel_news[i].b_sbj + "</li>";
          //   }
          //   textTemp += "</ul></div>";
          // }
          i = 1;
          var tmp = [];
          var tableText1 = "<div class='figure' style='width: ";
          var tableText2 = "'><img src='";
          var tableText3 = "' width='";
          var tableText4 = "px' alt='";
          var tableText5 = "'><span>";
          var tableText6 = "</span></div>";

          while (textTemp.match('<@IMG'+i+'>')){
            tmp = textTemp.match('<@IMG'+i+'>')
            if(imgOption[i-1].width > maxWidth) imgOption[i-1].width = maxWidth;
            textTemp = textTemp.replace(tmp[0],tableText1 + imgOption[i-1].width + 'px;' + imgAlign[i-1] + tableText2 
                                      + root + imgOption[i-1].img + tableText3 + imgOption[i-1].width 
                                      + tableText4 + tableText5
                                      + imgOption[i-1].img_c + tableText6);
            i++;
          }
          if(review_rate && review_rate.rate){
              let stars = '';
              for(i=0; i<review_rate.rate;i++){
                  if(review_rate.rate - i >= 1) stars += "★";
                  else stars += "☆";
              }
              textTemp += "<p><br></p><p>리뷰점수: " + stars + "</p>";
          }
          article = textTemp// + `<div style=" display: flex; margin: 80px 0 20px; padding: 20px 60px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;"><div style="height: 50px; width: 100%; background-color: aliceblue;"></div></div>`;
          console.log('생성된 미리보기: ',article)
          thisIns.$vs.loading.close(); 
          cb(article, preview);
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          else if(errorText.includes('415')){
            thisIns.$vs.notify({
              title:'Error',
              text: '잘못된 이미지가 포함되었습니다. 다른 이미지로 변경해주세요.',
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          }
          else{
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          }
          cb(descr);
        });
    },
    countCharacter(event) {
      // console.log(event.quill); //this.content.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/p><p>)?(.*?)<\/p>/))
      var lines = event.quill.getLines();
      var captionFlg = false;
      var imgIdx = 0;
      let textCount = 0;
      let limitCount = 1000;
      for(var i in lines){
        if(['H4','H5','H6'].find(e => e == lines[i].domNode.tagName)){
          lines[i].domNode.outerHTML = `<p>${lines[i].domNode.innerHTML}</p>`;
          return;
        }
        for(var j in lines[i].domNode.childNodes){
          // if(['H3'].find(e => e == lines[i].domNode.childNodes[j].tagName)){
          //   lines[i].domNode.childNodes[j].outerHTML = `<div style="border: 4px solid #D6D6D6;padding: 20px;margin: 0 0 45px;font-size: inherit;font-weight: inherit;position: relative;"><div style="position: absolute;left: 150px;bottom: -40px;height: 40px;width: 40px;overflow: hidden;"><div style="transform: translate(-20px, -17px);"><div style="box-sizing:border-box;border-bottom: 4px solid #D6D6D6;width: 148%;height:40px;transform: rotateZ(-40deg);background-color: white;"></div></div></div>${lines[i].domNode.childNodes[j].innerHTML}</div>`;
          //   return;
          // }
          if(['H4','H5','H6'].find(e => e == lines[i].domNode.childNodes[j].tagName)){
            lines[i].domNode.childNodes[j].outerHTML = `<p>${lines[i].domNode.childNodes[j].innerHTML}</p>`;
            return;
          }
          // if(lines[i].domNode.childNodes[j].style && lines[i].domNode.childNodes[j].style.fontSize){
          //   console.log(lines[i].domNode.childNodes[j].style.fontSize)
          // }
          // if(lines[i].domNode.childNodes[j].childNodes){
          //   for(const child of lines[i].domNode.childNodes[j].childNodes){
          //     if(child.style && child.style.fontSize){
          //       console.log(child.style.fontSize)
          //     }
          //   }
          // }
        }
        // console.log('line', i, lines[i])
        // console.log('line', i, lines[i], lines[(parseInt(i)+1)], lines[i].length())
        var _imgIdx = -1;
        for(j in lines[i].domNode.childNodes){
          if(lines[i].domNode.childNodes[j].tagName == 'IMG'){
            _imgIdx = j;
            break;
          }
        }
        if(_imgIdx > -1){
          if(captionFlg){
            lines[i].domNode.outerHTML = '<p>ⓒ</p>' + lines[i].domNode.outerHTML;
            event.quill.setSelection(event.quill.getSelection() + 2)
            return
          }
          lines[i].domNode.style.color = '';
          lines[i].domNode.style.textAlign = "left";
          lines[i].domNode.style.marginBottom = "";
          if(this.initImage && this.sendData.images.length){
            if(this.sendData.images[imgIdx].align == 'C') {
              lines[i].domNode.childNodes[_imgIdx].style.display = 'block';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0 auto';
            }else if(this.sendData.images[imgIdx].align == 'L') {
              lines[i].domNode.childNodes[_imgIdx].style.float = 'left';
              lines[i].domNode.childNodes[_imgIdx].style.display = 'inline';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0px 1em 1em 0px';
            }else if(this.sendData.images[imgIdx].align == 'R') {
              lines[i].domNode.childNodes[_imgIdx].style.float = 'right';
              lines[i].domNode.childNodes[_imgIdx].style.display = 'inline';
              lines[i].domNode.childNodes[_imgIdx].style.margin = '0px 1em 1em 0px';
            }
            imgIdx++;
          }
          if(!lines[i].domNode.childNodes[_imgIdx].style.float) {
            // console.log('test')
            lines[i].domNode.childNodes[_imgIdx].style.display = 'block';
            lines[i].domNode.childNodes[_imgIdx].style.margin = '0 auto';
          }
          if(_imgIdx > 0){
            var _tmpIdx = lines[i].domNode.outerHTML.indexOf('<img src');
            lines[i].domNode.outerHTML = lines[i].domNode.outerHTML.substring(0,_tmpIdx) + '</p><p>' + lines[i].domNode.outerHTML.substring(_tmpIdx);
            // console.log('inner image', 1, _imgIdx)
            return
          }else if(lines[i].domNode.childNodes.length > 1) {
            _tmpIdx = lines[i].domNode.outerHTML.indexOf(lines[i].domNode.childNodes[_imgIdx].outerHTML) + lines[i].domNode.childNodes[_imgIdx].outerHTML.length;
            lines[i].domNode.outerHTML = lines[i].domNode.outerHTML.substring(0,_tmpIdx) + '</p><p>' + lines[i].domNode.outerHTML.substring(_tmpIdx);
            // console.log('image+node', 1, _imgIdx)
            // lines[i].domNode.innerHTML = lines[i].children.head.domNode.outerHTML;
            return
          }
          else {
            if(i == lines.length - 1){
              lines[i].domNode.outerHTML += 'ⓒ</p>'
              return
            }
            captionFlg = true;
          }
          // if((parseInt(i)+1) < lines.length){
          //   if(lines[(parseInt(i)+1)].children){
          //     console.log('test1')
          //     if(lines[(parseInt(i)+1)].children.head.domNode.tagName != 'IMG') {
          //       console.log('test2')
          //       lines[(parseInt(i)+1)].domNode.style.textAlign = 'center';
          //       lines[(parseInt(i)+1)].domNode.style.color = '#DB2929';
          //     }
          //   }
          // }
        }
        else if(captionFlg){
          captionFlg = false;
          _imgIdx = -1;
          for(j in lines[i].domNode.childNodes){
            if(lines[i].domNode.childNodes[j].tagName == 'IMG'){
              _imgIdx = j;
              break;
            }
          }
          if(_imgIdx == -1){
            lines[i].domNode.style.color = "rgb(219, 41, 41)";
            lines[i].domNode.style.textAlign = "center";
            lines[i].domNode.style.marginBottom = "30px";
            // console.log('textContent: ', lines[i].domNode.textContent, lines[i].domNode.textContent.length)
            // for(const char of lines[i].domNode.textContent){
            //   // console.log('char:', char)

            // }
            if(lines[i].domNode.textContent == ''){ 
              lines[i].domNode.outerHTML = '<p>ⓒ</p>'
              event.quill.setSelection(event.quill.getSelection())
              return
            }
          }
        }else{
          if(textCount > limitCount){
            const getNode = function(n, v) {
              n = document.createElementNS("http://www.w3.org/2000/svg", n);
              for (var p in v)
                n.setAttributeNS(null, p.replace(/[A-Z]/g, function(m) { return "-" + m.toLowerCase(); }), v[p]);
              return n
            }
            console.log('over ', limitCount, ': ', lines[i])
            lines[i].domNode.style.borderTop = '1px solid blue';
            //"<svg xmlns='http://www.w3.org/2000/svg' version='1.1'height='50px' width='120px'><text x='0' y='15' fill='red' font-size='20'>I love SVG!</text></svg>"
            let bgSvgDom = getNode("svg");
            let textSvg = getNode("text", {x: 570, y: 20, fill: 'blue', 'font-size': 20});
            textSvg.innerHTML = limitCount;
            bgSvgDom.appendChild(textSvg);
            // console.log(bgSvgDom);
            lines[i].domNode.style.background = `url("data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(bgSvgDom))}")`;
            limitCount += 500;
          }else if(lines[i].domNode.style.borderTop == '1px solid blue'){
          // }else if(lines[i].domNode.className.includes('textBackground')){
            lines[i].domNode.style.borderTop = '';
            lines[i].domNode.style.background = '';
          }
          // console.log('text: ', lines[i].domNode.textContent, lines[i].domNode.textContent.length);
          textCount += lines[i].domNode.textContent.length;
          if(lines[i].domNode.style.color == "rgb(219, 41, 41)"){
            lines[i].domNode.style.color = '';
            lines[i].domNode.style.textAlign = "left";
            lines[i].domNode.style.marginBottom = "";
          }
        }
        // if(lines[i].children.tail.domNode.tagName == 'IMG' && lines[i].children.length > 1){ 
        //   lines[i].domNode.outerHTML = '<p>' + lines[i].domNode.innerText + '</p><p>' + lines[i].children.tail.domNode.outerHTML + '</p>';
        // }
      }
      this.initImage = false;
      // for(var i in event.quill.editor.delta.ops){
      //   if(event.quill.editor.delta.ops[i].insert.image){
      //     // console.log(i)
      //     if(event.quill.editor.delta.ops[i].attributes.color == "#ff9900"){
      //       console.log(event.quill.editor.delta.ops[i].attributes)
      //       event.quill.formatText(event.quill.getText().indexOf(event.quill.editor.delta.ops[i].insert), event.quill.editor.delta.ops[i].insert.length, '')
      //     }
      //   }
      // }
      // var tmpContent = this.decodeHtmlChar(this.content.replace(/\uFEFF|<span class="ql-cursor">(\uFEFF)?<\/span>|<span style="color: rgb(219, 41, 41);"><\/span>/g, ''));
      // var tmpContent2 = this.decodeHtmlChar(this.content.replace(/\uFEFF|<span class="ql-cursor">(\uFEFF)?<\/span>|<span style="color: rgb(219, 41, 41);"><\/span>/g, ''));
      // // console.log('tmp:', tmpContent);
      // var tmp, tmp2, tmp3;
      // if(tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/g)){
      //   tmp = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/g);
      //   // console.log('tmp:', tmp);
      //   for(var i=0; i < tmp.length; i++){
      //     // var j = i;
      //     tmp2 = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/span>)?(<\/p><p[^>]*>)?(.*?)<\/p>/);
      //     [tmp2, tmpContent, i] = this.captionImageCheck1(tmp2, tmpContent, i)
      //     console.log('tmp2: ', tmp2, i)
      //     if(tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g,'').length == 0){
      //       // console.log(tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g,'')[0])
      //       // console.log('set selection: ', event.quill.getSelection())
      //       event.quill.insertText(tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])).replace(/<\/p><p[^>]*>|&nbsp;|&amp;/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length + parseInt(i) + 1, ' ')
            
      //       // if(!event.quill.getSelection().index) event.quill.insertText(event.quill.getSelection() + 1, ' ')
      //       // else event.quill.insertText(event.quill.getSelection(), ' ')
      //       // if(tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g,'')[0].match(/ㅂ|ㅃ|ㅈ|ㅉ|ㄷ|ㄸ|ㄱ|ㄲ|ㅅ|ㅆ|ㅁ|ㄴ|ㅇ|ㄹ|ㅋ|ㅌ|ㅊ|ㅍ|ㅎ/)){
      //         //  this.content = this.content.replace('<span style="color: rgb(255, 153, 0);">' + tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g,'')[0], '<span style="color: rgb(255, 153, 0);">')
      //       // }
      //     }
      //     if(tmp2 && tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g, "") && (!tmp2[4].includes('<span style=') || tmp2[4].includes('<span style="color: rgb(219, 41, 41);"></span>')  )&& !tmp2[4].includes('<span class="ql-cursor">')) {
      //       // console.log(event.quill.getText().substring(0, tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])).replace(/<\/p><p[^>]*>|&nbsp;|&amp;/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length + parseInt(i)), '\nasdf\n', tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])), tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])).replace(/<\/p><p[^>]*>|&nbsp;|&amp;/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length + parseInt(i) + 1, tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g, "").replace(/&amp;/g, '&').length + 1)
            
      //       event.quill.formatText(tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])).replace(/<\/p><p[^>]*>|&nbsp;|&amp;/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length + parseInt(i) + 1, 
      //                             tmp2[4].replace(/<\/?([a-z]+[^>]*)>/g, "").replace(/&amp;/g, '&').length + 1, 'color', "#DB2929", 'user');
            
      //       return
      //     }
      //     // tmp3 = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/span>)?(<\/p><p[^>]*>)?(.*?)<\/p><p[^>]*>(.*?)<\/p>/)
      //     // if(tmp3[4].includes('<img ')){
      //     //   // console.log('tmp3 img:', tmp3);
      //     //   tmp3 = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/);
      //     //   tmpContent = tmpContent.replace(tmp3[0], '');
      //     //   // console.log('tmp3 img:', tmpContent);
      //     //   tmp3 = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/span>)?(<\/p><p[^>]*>)?(.*?)<\/p><p[^>]*>(.*?)<\/p>/)
      //     //   j++;
      //     // }
      //     // if(tmp3[4] && !tmp3[5].includes('<img ') && tmp3[5].includes('<span style="color: rgb(255, 153, 0);">')) {
      //     //   console.log('tmp3:', tmp3);
      //     //   event.quill.formatText(tmpContent2.substring(0, tmpContent2.indexOf(tmp[i])).replace(/<\/p><p[^>]*>/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length + tmp[i].match(/<\/p>/g).length + parseInt(j) + tmp3[4].replace(/<\/?([a-z]+[^>]*)>/g, "").length, 
      //     //                         tmp3[5].replace(/<\/?([a-z]+[^>]*)>/g, "").length + 1, 'color', "");
      //     //   break
      //     // }
      //     tmpContent = tmpContent.replace(tmp2[0], '');
      //   }
      //   var tmpContent3 = tmpContent.match(/<span style="color: rgb\(219, 41, 41\);">(.*)?<\/span>/)
      //   // console.log(tmpContent3, tmpContent2.indexOf(tmpContent3[0]), tmpContent2.substring(0, tmpContent2.indexOf(tmpContent3[0])).replace(/<\/p><p>/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length, tmpContent3[1].replace(/<\/?([a-z]+[^>]*)>/g, "").length + 1)
      //   if(tmpContent3 && !tmpContent3[1].includes('<span class="ql-cursor">')) event.quill.formatText(tmpContent2.substring(0, tmpContent2.indexOf(tmpContent3[0])).replace(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/g, "\n").replace(/<\/p><p>|&nbsp;|&amp;/g, "\n").replace(/<\/?([a-z]+[^>]*)>/g, "").length, tmpContent3[1].replace(/<\/?([a-z]+[^>]*)>/g, "").replace(/&amp;/g, '-').length + 1, 'color', '');
      // }


      this.content = this.content.replace('<p>&lt;iframe ', '<iframe ').replace('&gt;&lt;/iframe&gt;</p>', '></iframe>').replace('?autoPlay=true', '?autoPlay=false');
      // var strValue = this.content.replace(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/p><p[^>]*>)?(.*?)<\/p>/g,"").replace(/<\/?([a-z]+[^>]*)>/g, "");//event.quill.getText().replace(/\n/g,'');
      // var strLen = strValue.length; //글자수 셈하기
      // this.textcount = strLen;
      this.textcount = textCount;
      this.papers = parseInt((this.textcount)/200);
    },
    captionImageCheck1(tmp2, tmpContent, i){
      if(tmp2 && tmp2[4].includes('<img ')){
        var tmp = tmpContent.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>/);
        // console.log('tmp2 img:', tmp);
        var tmpContent1 = tmpContent.replace(tmp[0], '');
        tmp = tmpContent1.match(/<img[^>]*src=["']?([^>"']+)["']?[^>]*>(<\/span>)?(<\/p><p[^>]*>)?(.*?)<\/p>/);
        i++;
        if(!tmp){
          console.log('return');
          return [tmp2, tmpContent, i]
        }
        [tmp, tmpContent1, i] = this.captionImageCheck1(tmp, tmpContent1, i)
        return [tmp, tmpContent1, i]
      }else return [tmp2, tmpContent, i]
    },
    decodeHtmlChar(text){
      return text.replace(/&amp;|&quot;|&lt;|&gt;|&copy;|&deg;/g, ' ')
    },
    customButtonClick(editor, text) {
      // console.log(editor)
      editor.focus();
      editor.insertText(editor.getSelection().index, text)
    },
    changeEmail(){
      let tmpWriter = this.writer.find(data => data.idx == this.sendData.mno);
      this.sendData.byline = '데일리안 = ' + tmpWriter.nickname+ ' ' + tmpWriter.tail+ ' (' + this.sendData.email + ')';
      for(var i=0;i<this.tmpWriter2.length;i++){
        this.sendData.byline += ', ' + this.tmpWriter2[i].nickname+ ' ' + this.tmpWriter2[i].tail
      }
    },
    setWriter(val){
      console.log(val)
      this.sendData.mno = val.idx;
      this.sendData.name = val.nickname;
      this.sendData.tailname = val.tail ? ' ' + val.tail : '';
      // document.querySelector('.ql-customMacro').value = this.sendData.byline;
      if(val.email) this.sendData.email = val.email.replace('@ebn.co.kr', '@dailian.co.kr');
      else this.sendData.email = '';
      this.sendData.byline = '데일리안 = ' + this.sendData.name + this.sendData.tailname + ' (' + this.sendData.email + ')';
    },
    setWriter2(val){
      // console.log(val)
      if(val.length > 10){
        alert('최대 10명까지 입력 가능합니다.')
        this.tmpWriter2.splice(10);
      }
      // console.log('tmpWriter2: ',this.tmpWriter2)
      let tmpWriter = this.writer.find(data => data.idx == this.sendData.mno);
      if(tmpWriter) this.sendData.byline = '데일리안 = ' + this.sendData.name + this.sendData.tailname + ' (' + this.sendData.email + ')';
      this.sendData.another_writer = '';
      for(var i=0;i<this.tmpWriter2.length;i++){
        if(this.tmpWriter2[i]) {
          let tmpTail = this.tmpWriter2[i].tail ? ' ' + this.tmpWriter2[i].tail : '';
          this.sendData.another_writer += this.tmpWriter2[i].idx + ';';
          this.sendData.byline += ', ' + this.tmpWriter2[i].nickname + tmpTail
        }
      }
      // document.querySelector('.ql-customMacro').value = this.sendData.byline;
      console.log('another_writer: ',this.sendData.another_writer)
    },
    setCategory1(val){
      console.log('val:', val);
      if(val && val.find(e => e.code == 10)){
        if(val.length > 1){
          // console.log('in if');
          this.sendData.category1 =  [val.find(e => e.code == 10)];
        }
        this.states = this.$store.state.states;
        this.sendData.category2 = this.sendData.category2.filter(data=> data.first_id == 10);
        this.category2Disable = false;
        this.sendData.type = "9";
      }
      else{
        this.sendData.type = "1";
        if(this.sendData.state.code > 8){ this.states = this.$store.state.states.slice(0,2); }
      }
      // console.log('type: ', this.sendData.type);
      if(!val){
        this.sendData.category2 = [];
      }
      else if(val.length > 5){
        alert('최대 5개까지 입력 가능합니다.')
        val.splice(5);
        // this.tmpCategory1.splice(5);
      }
      // val = this.tmpCategory1;
      // val = ';';
      // if(this.sendData.category2 < val * 100 || this.sendData.category2 > (val+1) *  100) 
        // this.sendData.category2 = '';

      // var strCategory2 = ``

      this.sendData.category2.forEach((_category2)=>{
        if(_category2){
          var delFlg = true;
          val.forEach((_category1, index1)=>{
            if(_category2.first_id == _category1.code ) delFlg = false;
            // else if(_category2.code == 603 && _category1.code == 4) delFlg = false;
            if(index1 >= val.length - 1 && delFlg){
              this.sendData.category2 = this.sendData.category2.filter(data=> data.code != _category2.code);
              // console.log(this.sendData.category2, this.tmpCategory2);
            }
          })
        }
      })
      // this.setCategory2(this.sendData.category2)

      // var oldCategories2_view = []

      // for(var idx in this.categories2_view) {

      //   if(this.form.data.category2.categories[idx]) {

      //     oldCategories2_view.push(this.categories2_view[idx])
      //   }
      // }

      this.categories2_view = [];
      this.siteDisable = true;
      for(var i=0;i<val.length;i++){
        // val += this.tmpCategory1[i].code + ';';
        this.categories2_view = this.categories2_view.concat(this.categories2.filter(category => category.first_id == val[i].code));
        // if(val[i].code == 4) this.categories2_view = this.categories2_view.concat(this.categories2.find(e => e.code == 603));
        if(val[i].code == 9) this.siteDisable = false;
      }
      if(this.categories2_view.length) this.category2Disable = false;
      else this.category2Disable = true;
      if(val.length && (this.sendData.category2.length || this.category2Disable)){
        this.sendButtonDisable = false;
      }else this.sendButtonDisable = true;
      this.sendData.site = false;
      // console.log('series: ', val.findIndex(e2 => e2.code == 2) > -1);
      this.seriesArray = this.$store.state.series.filter(e => e.category1 == 0 || val.findIndex(e2 => e2.code == e.category1) > -1 );

      // for(idx in this.form.data.category2.categories) {

      //   this.form.data.category2.categories[idx] = false
      // }
      // var matchIdx
      // this.form.data.category2.toString = ``
      
      // this.sendData.category2 = []
      // for(idx in oldCategories2_view) {

      //   matchIdx = this.categories2_view.indexOf(oldCategories2_view[idx])

      //   if(matchIdx != -1) {
          
      //     this.form.data.category2.categories[matchIdx] = true

      //     if(this.form.data.category2.toString == ``) {
      //       this.form.data.category2.toString += this.categories2_view[matchIdx].name
      //     }
      //     else {
      //       this.form.data.category2.toString += `, ${this.categories2_view[matchIdx].name}`
      //     }

      //     this.sendData.category2.push(this.categories2_view[matchIdx])
      //   }
      // }
    },
    setCategory2(val){
      console.log(val, this.tmpCategory2)
      if(val.length > 5){
        alert('최대 5개까지 입력 가능합니다.')
        this.sendData.category2.splice(5);

        this.form.data.category2.toString = ``

        console.log('this.sendData.category2::', this.sendData.category2)
        console.log('this.sendData.category2[0].name:', this.sendData.category2[0].name)

        for(var idx in this.sendData.category2) {

          if(this.form.data.category2.toString == ``) {

            this.form.data.category2.toString = this.sendData.category2[idx].name
          }
          else {
            this.form.data.category2.toString += `, ${this.sendData.category2[idx].name}`
          }
        }

        var trueCount = 0
        for(idx in this.form.data.category2.categories) {

          if(this.form.data.category2.categories[idx]) {
            
            trueCount++
          }

          if(trueCount > 5) {
            
            for(var i = idx; i < this.form.data.category2.categories.length; i++) {

              this.form.data.category2.categories[i] = false
            }

            break
          }
         }
      }
      if(this.sendData.category2.findIndex(e => e.code == 509 || e.code == 608) > -1){
        this.reviewDisable = false;
      }else{
        this.sendData.review_rate = {'rate': ''};
        this.reviewDisable = true;
      }
      if(this.sendData.category2.findIndex(e => e.code == 1007 || e.code == 1008) > -1){
        this.sendData.photoType = "2";
      }else{
        this.sendData.photoType = "1";
      }
      // console.log('photoType: ', this.sendData.photoType)
    },
    setSeries(val){
      // console.log("series:", val)
      const targets = [129, 82, 116, 121];
      if(val && val.code){
        if(targets.find(e => e == val.code)){
          if(!this.sendData.category1.find(e => e.code == 1)){
            this.sendData.category1.push(this.categories1.find(e => e.code == 1))
            this.setCategory1(this.sendData.category1);
          }
          if(!this.sendData.category2.find(e => e.code == 106)){
            this.sendData.category2.push(this.categories2.find(e => e.code == 106))
            this.setCategory2(this.sendData.category2);
          }
        }
      }
    },
    photoCheck(e){
      // console.log('photo check', e)
      if(e == 'on'){
        this.sendData.category1 = [this.categories1[9]];
        this.setCategory1(this.sendData.category1);
        // this.setCategory1([this.categories1[9]]);
        // if(!this.sendData.category1.find(e => e.code == 10)) 
        //   this.sendData.category1 = [this.categories1[9]].concat(this.sendData.category1);
        // else this.sendData.category1 = [this.categories1[9]].concat(this.sendData.category1.filter(e => e.code != 10));
      }else{
        this.sendData.category1 = this.sendData.category1.filter(e => e.code != 10);
        this.setCategory1(this.sendData.category1);
        const customSpecialPickerItems3 = Array.prototype.slice.call(document.querySelectorAll('#toolbar3 .ql-customSpecial .ql-picker-item'));
        customSpecialPickerItems3.forEach(item => item.textContent = item.dataset.value);
        document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML
            = '특수문자' + document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML;
      }
    },
    watermarkToggle(e){
      // console.log(e)
      if(e.value) { this.sendData.watermark = this.activeUserInfo.watermark }
      else { this.sendData.watermark = ''; }
    },
    addFile(e){
      console.log(e, e.target.files);
      this.submitFiles(e.target.files);
    },
    submitFiles(files){
      let formData = new FormData();
      let thisIns = this;

      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        formData.append('file', file);
      }
      formData.append('newsId', this.sendData.id)
      this.$http.post( '/api/writeArticles/thumbnailUpload',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          // onUploadProgress: function( progressEvent ) {
          //   this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          // }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        thisIns.sendData.icon = response.data;
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },
    created() {
      
      // const root = "https://cdnimage.dailian.co.kr/news/";
      // console.log(this.$store.state.states, this.$store.state.maxWidth)
      this.sendData.mainTitle = "";
      this.sendData.subTitle = "";
      this.sendData.state = this.$store.state.states.find(e => e.code == 9);
      this.pastState = "9";
      this.sendData.site = false;
      this.states = this.$store.state.states.slice(0,2);
      this.sendData.type = "1";
      this.sendData.category1 = [];
      this.sendData.category2 = [];
      this.sendData.review_rate = {'rate': ''};
      this.sendData.series = null;
      // this.sendData.series = [];
      this.sendData.images = [];
      this.sendData.icon = '';
      // this.sendData.arrayBase64 = [];
      // this.sendData.arrayImageAlign = [];
      // this.sendData.arrayImageText = [];
      // this.sendData.arrayImageWidth = [];
      this.sendData.tag = [];
      this.content = "";
      this.sendData.out_site = "";
      this.sendData.mno = this.$store.state.AppActiveUser.id;
      this.tmpWriter = this.writer.find(data => data.idx == this.sendData.mno);
      this.sendData.writer = this.$store.state.AppActiveUser.id;
      this.sendData.another_writer = '';
      this.tmpWriter2 = [];
      this.sendData.name = this.$store.state.AppActiveUser.name;
      this.sendData.tailname = this.$store.state.AppActiveUser.tail;
      if(this.$store.state.AppActiveUser.email) { this.sendData.email = this.$store.state.AppActiveUser.email.replace('@ebn.co.kr', '@dailian.co.kr'); }
      else {this.sendData.email = '';}
      this.sendData.byline = '데일리안 = ' + this.$store.state.AppActiveUser.name
                              + ' ' + this.$store.state.AppActiveUser.tail + ' (' + this.sendData.email + ')';
      this.sendData.d_pass = new Date();
      this.sendData.sns = {};
      this.facebookFlg = false;
      this.twitterFlg = false;
      this.news_rel = "";
      // console.log('query id:' + this.$route.query.id, this.sendData.writer);
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      if(this.$route.query.id){
        this.sendData.id = this.$route.query.id;
            thisIns.$http.post('/api/getSingleNews', thisIns.$route.query)
              .then(function (response) {
                console.log('get news', response);
                if(response.data.deadDocument) {
                  return thisIns.$router.back();
                }

                if(thisIns.sendData.mno != response.data[0][0].mno && thisIns.$store.state.AppActiveUser.level < 8 && !( response.data[0][0].option1 == thisIns.$store.state.AppActiveUser.department && thisIns.$store.state.AppActiveUser.permission.departmentEdit)){
                  alert('작성자가 아닙니다!');
                  thisIns.autoSaveFlg = false;
                  thisIns.lockFlg = false;
                  thisIns.$router.back();
                }
                thisIns.sendData.mainTitle = response.data[0][0].b_sbj.replace(/<\/?(h[^>]*|p[^>]*|div[^>]*)>/g, "");
                thisIns.sendData.portalTitle = response.data[0][0].bp_sbj.replace(/<\/?(h[^>]*|p[^>]*|div[^>]*)>/g, "");
                thisIns.sendData.subTitle = response.data[0][0].s_sbj.replace(/<\/?(h[^>]*|span[^>]*|div[^>]*|strong[^>]*)>/g, "");
                thisIns.sendData.mno = response.data[0][0].mno;
                thisIns.tmpWriter = thisIns.writer.find(data => data.idx == thisIns.sendData.mno);
                if(thisIns.tmpWriter) {
                  thisIns.setWriter(thisIns.tmpWriter);
                  thisIns.sendData.name = thisIns.tmpWriter.nickname;
                  thisIns.sendData.tailname = thisIns.tmpWriter.tail ? thisIns.tmpWriter.tail : '' ;
                }else {
                  thisIns.sendData.name = response.data[0][0].name;
                }
                console.log(thisIns.sendData.name)
                // thisIns.sendData.writer = response.data[0][0].mno;
                thisIns.sendData.another_writer = response.data[0][0].another_writer;
                // console.log(thisIns.sendData.another_writer.split(';'));
                if(thisIns.sendData.another_writer){
                  thisIns.sendData.another_writer.split(';').forEach(data =>{
                    if(data)
                    thisIns.tmpWriter2.push(thisIns.writer.find(_data => _data.idx == data))
                  });
                }
                thisIns.setWriter2(thisIns.tmpWriter2);
                if(response.data[0][0].email){
                  thisIns.sendData.email = response.data[0][0].email.replace('@ebn.co.kr', '@dailian.co.kr');
                }else {
                  thisIns.sendData.email = '';
                }

                thisIns.sendData.state = thisIns.$store.state.states.find(e => e.code == response.data[0][0].state);
                thisIns.pastState = response.data[0][0].state;
                if(thisIns.sendData.state.code > 8) thisIns.states = thisIns.$store.state.states.slice(0,2);
                if(thisIns.sendData.state.code == 1) thisIns.states = thisIns.$store.state.states.filter(e => e.code == 1 || e.code == 0);
                else {
                  thisIns.states = thisIns.$store.state.states;
                  thisIns.states = [thisIns.states.find(e => e.code == 2)].concat(thisIns.states.filter(e => e.code != 2));
                }
                if(response.data[0][0].site == 'Y') thisIns.sendData.site = true;
                else thisIns.sendData.site = false;
                // console.log('site: ' + thisIns.sendData.site, response.data[0][0].site);
                thisIns.sendData.type = response.data[0][0].type_code;
                // console.log('category1: ', response.data[4]);
                for(var i=0; i < response.data[4].length; i++){
                  thisIns.sendData.category1.push(thisIns.categories1.find(data => data.code == response.data[4][i].code))
                }
                if(!response.data[4].length){
                  if(thisIns.$store.state.AppActiveUser.department == 'g1'){
                    // console.log('category find: ' ,thisIns.categories1.filter(e => e.code == 2 || e.code == 3))
                    thisIns.sendData.category1 = thisIns.categories1.filter(e => e.code == 2);
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g2'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g6'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g7'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                    thisIns.sendData.category2 = [thisIns.categories2.find(e => e.code == 410)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g3'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                    thisIns.sendData.category2 = [thisIns.categories2.find(e => e.code == 411)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g14'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                    thisIns.sendData.category2 = [thisIns.categories2.find(e => e.code == 408)];
                  // }else if(thisIns.$store.state.AppActiveUser.department == 'g11'){
                  //   thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 4)];
                  //   thisIns.sendData.category2 = [thisIns.categories2.find(e => e.code == 411)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g4'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 9)];
                  }else if(thisIns.$store.state.AppActiveUser.department == 'g5'){
                    thisIns.sendData.category1 = [thisIns.categories1.find(e => e.code == 8)];
                  }else thisIns.sendData.category1 = [];
                }

                // console.log('category2: ', response.data[5]);
                for(i=0; i < response.data[5].length; i++){
                  thisIns.sendData.category2.push(thisIns.categories2.find(data => data.code == response.data[5][i].code))
                }
                thisIns.sendData.category2 = thisIns.sendData.category2.filter(e => e)
                // for(i=0; i < response.data[7].length; i++){
                //   thisIns.sendData.series.push(thisIns.seriesArray.find(data => data.code == response.data[7][i].code))
                // }
                thisIns.sendData.series = thisIns.seriesArray.find(e => e.code == response.data[0][0].series);

                if(response.data[6].length){
                  // thisIns.relNewsListType = "search";
                  thisIns.sendData.rel = response.data[6];
                  thisIns.tmp_rel = response.data[6];
                  thisIns.news_rel = "<h3>관련기사</h3><ul>"
                  for(i=0; i < response.data[6].length; i++){
                    thisIns.news_rel += "<li>" + response.data[6][i].b_sbj + "</li>"
                  }
                  thisIns.news_rel += "</ul>"

                }
                thisIns.photoSetArray = response.data[8];
                thisIns.sendData.photoSet = thisIns.photoSetArray.find(e => e.psId == response.data[0][0].photoSet);
                console.log('photoSet: ', response.data[0][0].photoSet, thisIns.sendData.photoSet)

                for(var idx in thisIns.sendData.category1) {
                  
                  let inputIdx = thisIns.categories1.indexOf(thisIns.sendData.category1[idx])
                  thisIns.form.data.category1.categories[inputIdx] = true

                  if(thisIns.form.data.category1.toString == "1차 카테고리 선택") {
                    
                    thisIns.form.data.category1.toString = thisIns.sendData.category1[idx].name
                  }
                  else {

                    thisIns.form.data.category1.toString += `, ${thisIns.sendData.category1[idx].name}`
                  }
                }

                console.log('thisIns.sendData.category2:', thisIns.sendData.category2)

                thisIns.categories2_view = []
                for(i=0;i<thisIns.sendData.category1.length;i++){
                  thisIns.categories2_view = thisIns.categories2_view.concat(thisIns.categories2.filter(category => category.first_id == thisIns.sendData.category1[i].code));
                  if(thisIns.sendData.category1[i].code == 9) thisIns.siteDisable = false;
                }
                for(idx in thisIns.sendData.category2) {

                  let inputIdx = thisIns.categories2_view.indexOf(thisIns.sendData.category2[idx])
                  thisIns.form.data.category2.categories[inputIdx] = true

                  if(inputIdx != -1) {

                    if(thisIns.form.data.category2.toString == "2차 카테고리 선택" || thisIns.form.data.category2.toString == ``) {
                      
                      thisIns.form.data.category2.toString = thisIns.categories2_view[inputIdx].name
                    }
                    else {

                      thisIns.form.data.category2.toString += `, ${thisIns.categories2_view[inputIdx].name}`
                    }
                  }
                }
                thisIns.setCategory1(thisIns.sendData.category1);
                thisIns.setCategory2(thisIns.sendData.category2);
                // thisIns.sendData.category1 = response.data[0][0].category1;
                // thisIns.sendData.category1.split(';').forEach((_category1)=>{
                //   if(_category1) thisIns.tmpCategory1.push(thisIns.categories1.find(data => data.code == _category1));
                // })
                // console.log('category1: ' + thisIns.sendData.category1);
                // thisIns.sendData.category2 = response.data[0][0].category2;
                // thisIns.sendData.category2.split(';').forEach((_category2)=>{
                //   if(_category2) thisIns.tmpCategory2.push(thisIns.categories2.find(data => data.code == _category2));
                // })
                thisIns.sendData.review_rate = thisIns.reviewOption.find(e => e.rate == response.data[0][0].review_rate);
                // console.log('series: ', thisIns.sendData.review_rate);
                thisIns.sendData.out_site = response.data[0][0].out_site;
                let tmpOutSite = thisIns.sendData.out_site.split("|");
                if(response.data[0][0].state == 2){
                  let tmpPortal = [];
                  try {
                    tmpPortal = JSON.parse(response.data[0][0].out_reserv);
                  } catch (error) {
                    console.log(error);
                  }
                  thisIns.sendData.portalAction = tmpPortal;
                  tmpOutSite = [];
                  for(const item of tmpPortal){
                    tmpOutSite.push(item.name);
                  }
                }else{
                  for(i = 0; i<thisIns.portals.length; i++){
                    if(!thisIns.sendData.out_site){
                      thisIns.sendData.portalAction.push(JSON.parse(JSON.stringify(thisIns.portals[i])));
                    }
                    else if(tmpOutSite.find(e => e.toLowerCase() == thisIns.portals[i].name.toLowerCase() || e == thisIns.portals[i].oldName )){
                      thisIns.sendData.portalAction.push(JSON.parse(JSON.stringify(thisIns.portals[i])));
                    }
                  }
                }
                console.log('out_site: ', thisIns.sendData.portalAction);


                thisIns.sendData.sns = response.data[0][0].sns || {};
                // thisIns.facebookFlg = thisIns.sendData.sns.facebookFlg || false;
                // thisIns.twitterFlg = thisIns.sendData.sns.twitterFlg || false;
                if(thisIns.sendData.sns.facebookId){
                  thisIns.sendData.sns.facebookFlg = false;
                }
                if(thisIns.sendData.sns.twitterId){
                  thisIns.sendData.sns.twitterFlg = false;
                }

                // console.log('senddata: ', thisIns.sendData)

                
                if(thisIns.sendData.state.code < 3 || response.data[0][0].d_pass > moment())
                  thisIns.sendData.d_pass = new Date(moment(response.data[0][0].d_pass));
                thisIns.sendData.images = response.data[1];
                for(i in thisIns.sendData.images){
                  if(thisIns.sendData.images[i].pno){
                    thisIns.photoData.push({'img': thisIns.sendData.images[i].img, 'pno': thisIns.sendData.images[i].pno});
                  }
                }
                thisIns.sendData.icon = response.data[0][0].icon;
                if(response.data[0][0].photoType){
                  thisIns.sendData.photoType = response.data[0][0].photoType;
                }
                // console.log('photoData: ', thisIns.photoData);
                // for(i=0;i<images.length;i++){
                //   thisIns.sendData.arrayBase64.push(images[i].img);
                //   thisIns.sendData.arrayImageText.push(images[i].img_c);
                //   thisIns.sendData.arrayImageAlign.push(images[i].align);
                //   thisIns.sendData.arrayImageWidth.push(images[i].width);
                // }
                let tags = response.data[2];
                for(i=0;i<tags.length;i++){
                  thisIns.sendData.tag.push(tags[i].tag);
                }
                let titleTags = response.data[9];
                for(i=0;i<titleTags.length;i++){
                  thisIns.sendData.titleTags.push(titleTags[i].title_tag)
                }
                if(response.data[10] && response.data[10][0]){
                  thisIns.lastArticleTime = moment(response.data[10][0].d_pass);
                }
                if(response.data[11]){
                  thisIns.forceCategoryList = response.data[11];
                }
                


                // thisIns.sendData = JSON.parse(JSON.stringify(thisIns.sendData));


                // console.log(response.data[3]);
                thisIns.lockHistory = '';
                let logs = response.data[3];
                logs.forEach(log => {
                  thisIns.lockHistory += log.name + ': ' + log.state + ' at ' + log.access_date + ' \n';
                })
                if(logs[0] && logs[0].state == 'lock' && moment(logs[0].access_date) > moment().subtract(1, 'hour') && logs[0].reporter_idx != thisIns.$store.state.AppActiveUser.id ){
                  console.log('lock: ', logs[0].reporter_idx, thisIns.$store.state.AppActiveUser.id)
                  if(thisIns.$store.state.AppActiveUser.permission.delete){
                    alert(`${logs[0].name}기자가 수정중입니다. \n동시에 수정할 경우 둘중 한쪽의 수정사항이 \n적용되지 않을 수 있습니다.\n` + parseInt((moment(logs[0].access_date) - moment().subtract(1, 'hour')) / 1000 / 60  + 1) + '분 남음' )
                    thisIns.previewDisable = false;
                  }else{
                    alert('잠겨있는 기사입니다. 수정할 수 없습니다. ' + `\n${logs[0].name}기자가 수정중입니다.` + parseInt((moment(logs[0].access_date) - moment().subtract(1, 'hour')) / 1000 / 60  + 1) + '분 남음' )
                    thisIns.autoSaveFlg = false;
                    thisIns.lockFlg = false;
                    thisIns.previewDisable = true;
                  }
                }else{
                  thisIns.previewDisable = false;
                  thisIns.$http.post('/api/writeArticles/lock', {id: thisIns.sendData.id, name: thisIns.$store.state.AppActiveUser.name, mno: thisIns.$store.state.AppActiveUser.id, lock: 'lock'})
                    .then(function (response) {
                      console.log(response);
                      thisIns.lockHistory = thisIns.$store.state.AppActiveUser.name + ': lock at ' + moment().format('YYYY-MM-DD HH:mm:ss') + ' \n' + thisIns.lockHistory;
                    })
                    .catch(function (error) {
                      console.log(error);
                      thisIns.$vs.notify({
                        title:'Error',
                        text: error,
                        color:'danger',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'})
                      thisIns.$vs.loading.close();  
                    });
                }
                // console.log(logs[0].state, logs[0].access_date,logs[0].reporter_idx, thisIns.$store.state.AppActiveUser.id )
                thisIns.content = thisIns.loadData(response.data[0][0].descr);
                thisIns.sendButtonDisable = true;
                // thisIns.contentView = thisIns.descrToArticle(response.data[0][0].descr, [thisIns.sendData.arrayBase64, thisIns.sendData.arrayImageAlign, thisIns.sendData.arrayImageText, thisIns.sendData.arrayImageWidth]);
                // console.log(response.data, moment(response.data[0][0].d_pass).format('YYYY-MM-DD HH:mm'));
                if(response.data[0][0].byline) { thisIns.sendData.byline = response.data[0][0].byline }
                thisIns.$http.post('/api/getNewsList/photo', {'startRow': 0, 
                                                              'itemsPerPage': 50000, 
                                                              'state': null,
                                                              'category1': null,
                                                              'writer': null,
                                                              'searchText': '',
                                                              'startDate': moment().subtract(2, 'week'),
                                                              'endDate': moment()
                                                              })
                  .then(function (response) {
                    console.log('response_response)', response)
                    for(var i in response.data[1]){
                      thisIns.photoData.push({'img': response.data[1][i].mimg, 'pno': response.data[1][i].pno});
                    }
                  })
                  .catch(function (error) {
                    thisIns.$vs.notify({
                      title:'Error',
                      text: error,
                      color:'danger',
                      iconPack: 'feather',
                      icon:'icon-alert-circle'})
                    thisIns.$vs.loading.close();  
                  });

                if(thisIns.$route.query.pno){
                  thisIns.sendData.category1 = thisIns.categories1.filter(e => e.code == 10);
                  thisIns.setCategory1(thisIns.sendData.category1);
                  // for(var idx in thisIns.categories1) {
                  //   if(thisIns.categories1[idx].name == "포토") {

                  //     thisIns.form.data.category1.categories[idx] = {name: "포토", code: 10}
                  //     thisIns.form.data.category1.toString = "포토"
                  //   }
                  // }

                  console.log('thisIns.form.data.category1.categories: ', thisIns.form.data.category1.categories)

                  thisIns.$http.post('/api/writeArticles/photo', {'pno': thisIns.$route.query.pno})
                    .then(function (response) {
                      console.log(response)
                      const photoRoot = "https://cdnimage.dailian.co.kr/photo/";
                      const newsImg = "https://cdnimage.dailian.co.kr/news/";
                      thisIns.content = '';
                      for(var i in response.data){
                        if(response.data[i].mimg.includes('/news_')){
                          thisIns.content += `<p><img src="` + newsImg + response.data[i].mimg + `" /></p><p>` + response.data[i].contents + `</p>`;
                        }else thisIns.content += `<p><img src="` + photoRoot + response.data[i].mimg + `" /></p><p>` + response.data[i].contents + `</p>`;
                      }
                      thisIns.sendData.mainTitle = response.data[0].subject;
                      thisIns.sendData.portalTitle = response.data[0].subject;

                      thisIns.$vs.loading.close();  
                    })
                    .catch(function (error) {
                      thisIns.$vs.notify({
                        title:'Error',
                        text: error,
                        color:'danger',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'})
                      thisIns.$vs.loading.close();  
                    });
                }else thisIns.$vs.loading.close();  
              })
              .catch(function (error) {
                console.log(error);
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
              });
      }
      else {
        this.sendData.mainTitle = "";
        this.sendData.text = "";
        this.sendData.id = '';
        const thisIns = this;
          // console.log(this.sendData)
        this.$http.post('/api/writeArticles', this.sendData)
          .then(function (response) {
            console.log(response)
            thisIns.autoSaveFlg = false;
            if(thisIns.$route.query.pno) thisIns.$router.push({path: thisIns.$route.path, query: {id: response.data.id, pno: thisIns.$route.query.pno}});
            else thisIns.$router.push({path: thisIns.$route.path, query: {id: response.data.id}});
            thisIns.$vs.loading.close();  
          })
          .catch(function (error) {
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          });
      }
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  computed: {
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  created() {
    console.log(this.sendData);
    this.categories1 = this.$store.state.categories1;
    this.categories2 = this.$store.state.categories2;
    this.types = this.$store.state.types;
    this.writer = this.$store.state.writer;
    this.writerList = this.writer.filter(e => moment(e.entry_date) > moment('2001-01-01'));
    this.portals = this.$store.state.portals;
    this.seriesArray = this.$store.state.series;
    this.created();
  },
  mounted(){
    const customSpecialPickerItems = Array.prototype.slice.call(document.querySelectorAll('#toolbar .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar .ql-customSpecial .ql-picker-label').innerHTML;
    const customSpecialPickerItems2 = Array.prototype.slice.call(document.querySelectorAll('#toolbar2 .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems2.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar2 .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar2 .ql-customSpecial .ql-picker-label').innerHTML;
    const customSpecialPickerItems3 = Array.prototype.slice.call(document.querySelectorAll('#toolbar3 .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems3.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar3 .ql-customSpecial .ql-picker-label').innerHTML;
    const customSpecialPickerItems4 = Array.prototype.slice.call(document.querySelectorAll('#toolbar4 .ql-customSpecial .ql-picker-item'));
    customSpecialPickerItems4.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('#toolbar4 .ql-customSpecial .ql-picker-label').innerHTML
        = '특수문자' + document.querySelector('#toolbar4 .ql-customSpecial .ql-picker-label').innerHTML;
    document.querySelector('#toolbar .ql-blockquote').innerHTML
        = '<b style="font-size: 16px; line-height: 19px;">내부부제</b>';
    document.querySelector('#toolbar .ql-code-block').innerHTML
        = `<b style="font-size: 16px; line-height: 19px;">내부단락</b>`;
    document.querySelectorAll('#toolbar .ql-header').forEach(item => {
      item.innerHTML
        = `<b style="font-size: 16px; line-height: 19px;">${item.value == 3 ? '말풍선' : '박스' + item.value}</b>`;
    })
    document.querySelector('#toolbar2 .ql-strike').innerHTML
        = `<b style="font-size: 14px; line-height: 19px;">원문자</b>`;
    document.querySelector('#toolbar3 .ql-strike').innerHTML
        = `<b style="font-size: 14px; line-height: 19px;">원문자</b>`;
    document.querySelector('#toolbar .ql-strike').innerHTML
        = `<b style="font-size: 14px; line-height: 19px;">원문자</b>`;
    
    // console.log(document.querySelector('.quillEditor_descr .ql-toolbar'));
    // document.querySelector('.ql-customMacro').innerHTML = '매크로' + document.querySelector('.ql-customMacro').innerHTML;
    // document.querySelector('.ql-customMacro').value = this.sendData.byline;
    
    var thisObj = this

    ImageResize.setOnChangedListener(function(image, styleName) {
      
      console.log('listener:', image, styleName)
      thisObj.$refs.quillEditor_descr.quill.insertText(0, ' ')
      thisObj.$refs.quillEditor_descr.quill.deleteText(0, 1)
    })
    setTimeout(() => { this.timeFlg = true; }, 2000);
  },
}
  </script>

<style lang="scss">
@import "@/assets/scss/custom-style/writeArticles.scss";

.titleTags .vs__search, .titleTags .vs__search:focus{
  width: auto;
}
</style>